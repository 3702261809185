import React from 'react';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';
import styles from './RenderHTML.module.scss';

/**
 * Render HTML
 * @component
 */
function RenderHTML({ htmlString, short }) {
  let fixedData;
  fixedData = htmlString.replace(/<pre/g, '<p');
  fixedData = fixedData.replace(/<\/pre>/g, '</p>');
  fixedData = fixedData.replace(/width="[^"]*"/g, 'width="100%"');
  fixedData = fixedData.replace(/ height="[^"]*"/g, '');
  fixedData = fixedData.replace(/&nbsp;/, ' ');

  const extractFirstTag = (input) => {
    const regex = /<[^>]+>.*?<\/[^>]+>/;
    const match = input.match(regex);
    if (match) {
      return match[0].replace(/&nbsp;/, ' ');
    }
    return input;
  };

  if (short) {
    return (
      <div className={styles.firstString}>
        {extractFirstTag(fixedData).replace(/<[^>]+>/g, '')}
      </div>
    );
  }

  return <div className={styles.fixHtml}>{parse(fixedData)}</div>;
}

RenderHTML.propTypes = {
  htmlString: PropTypes.string.isRequired,
  short: PropTypes.bool,
};
RenderHTML.defaultProps = {
  short: false,
};

export default RenderHTML;
