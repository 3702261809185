import { useContext } from 'react';
import { StreamsContext } from '../hoc/StreamsProvider';

export default function useStreams() {
  const context = useContext(StreamsContext);
  if (context === null) {
    throw new Error('useCourses must be used within a CoursesProvider');
  }
  return context;
}
