/* eslint-disable no-underscore-dangle */
import { api } from './configs/axiosConfig';
import {
  defineCancelAPIObject,
  APIError,
  showAPIError,
} from './configs/axiosUtils';
import { isResponse2xx, isResponse4xx } from '../utils/responses';

export const coursesAPI = {
  // Define the cancel API object
  _cancelApiObject: function _cancelApiObject() {
    defineCancelAPIObject(this);
  },

  // Prepare cancellation signal for the method with specified name
  _getCancellationSignal: function getSignal(name, cancel) {
    if (cancel) {
      return this._cancelApiObject[name].handleRequestCancellation().signal;
    }
    return undefined;
  },

  /**
   * Login user and return access and refresh tokens
   * @param {String} login_ User login
   * @param {String} password User Password
   * @param {String} type Type of login, one of LoginTypeEnum
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Server response or null in case of unexpected error
   */
  login: async function login(login_, password, type, cancel = false) {
    const url = '/authentication/jwt';
    const params = {
      Login: login_,
      Password: password,
      Type: type,
    };

    const response = await api
      .request({
        url,
        method: 'GET',
        params,
        signal: this._getCancellationSignal(this.login.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200 || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Request sending 4-digit code to the phone
   * @param {String} phoneNumber Phone numner to which to send the code
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Server response or null in case of unexpected error
   */
  requestLoginPhoneCode: async function requestLoginPhoneCode(
    phoneNumber,
    cancel = false
  ) {
    const url = 'authentication/jwt/passwordless/phone';
    const data = {
      phoneNumber,
    };
    const response = await api
      .request({
        url,
        method: 'POST',
        data,
        signal: this._getCancellationSignal(
          this.requestLoginPhoneCode.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response?.status) || isResponse4xx(response?.status)) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get Jwt token by 4-digit code
   * @param {String} phoneNumber User phone number
   * @param {String} code 4-digit confirmation code
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Server response or null in case of unexpected error
   */
  getJwtByPhoneCode: async function getJwtByPhoneCode(
    phoneNumber,
    code,
    cancel = false
  ) {
    const url = 'authentication/jwt/passwordless/phone';
    const params = {
      PhoneNumber: phoneNumber,
      Code: code,
    };
    const response = await api
      .request({
        url,
        method: 'GET',
        params,
        signal: this._getCancellationSignal(
          this.getJwtByPhoneCode.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200 || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Refresh tokens using the valid refresh token
   * @param {String} token Valid refresh token
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Associative array with new access and refresh tokens or null
   */
  refreshTokens: async function refreshTokens(token, cancel = false) {
    const url = '/authentication/jwt';
    const data = {
      Token: token,
    };
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api
      .request({
        url,
        method: 'PUT',
        headers,
        data,
        signal: this._getCancellationSignal(this.refreshTokens.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return response.data;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Request confirmation code for user invited by phone
   */
  createUnregisteredUser: async function createUnregisteredUser(
    userPublicInfo,
    isRegisteredByPhone,
    cancel
  ) {
    const url = '/registration';
    const fieldName = isRegisteredByPhone ? 'phoneNumber' : 'email';
    const data = {
      [fieldName]: userPublicInfo,
    };
    const response = await api
      .request({
        url,
        method: 'POST',
        data,
        signal: this._getCancellationSignal(
          this.createUnregisteredUser.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response?.status) || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  requestConfirmationCode: async function requestConfirmationCode(
    userPublicInfo,
    isRequestedByPhone,
    cancel
  ) {
    const url =
      /confirmation/ +
      (isRequestedByPhone
        ? `phone?phone=${userPublicInfo}`
        : `email?email=${userPublicInfo}`);
    const response = await api
      .request({
        url,
        method: 'POST',
        signal: this._getCancellationSignal(
          this.requestConfirmationCode.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response?.status) || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  confirmInviteByCode: async function confirmInviteByCode(
    userPublicInfo,
    code,
    isRequestedByPhone,
    cancel
  ) {
    const url = `/confirmation/${isRequestedByPhone ? 'phone' : 'email'}`;
    const fieldName = isRequestedByPhone ? 'phoneNumber' : 'email';
    const data = {
      code,
      [fieldName]: userPublicInfo,
    };
    const response = await api
      .request({
        url,
        method: 'PUT',
        data,
        signal: this._getCancellationSignal(
          this.confirmInviteByCode.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response?.status)) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Register invited user
   * @param {String} email User's email
   * @param {String} phoneNumber User's phone number
   * @param {String} name User's name for profile
   * @param {String} lastName User's last name for profile
   * @param {String} password Password for login
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Server response or null in case of unexpected error
   */
  registerInvitedUser: async function registerInvitedUser(
    email,
    phoneNumber,
    name,
    lastName,
    password,
    cancel = false
  ) {
    const url = '/registration';
    const data = {
      email,
      phoneNumber,
      name,
      lastName,
      password,
    };
    const response = await api
      .request({
        url,
        method: 'PUT',
        data,
        signal: this._getCancellationSignal(
          this.registerInvitedUser.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));
    if (isResponse2xx(response?.status) || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get current user
   * @param {String} token Valid access token
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Object with user or null
   */
  getUser: async function getUser(token, cancel = false) {
    const url = '/authorized/user';
    const headers = { Authorization: `Bearer ${token}` };

    const response = await api
      .request({
        url,
        method: 'GET',
        headers,
        signal: this._getCancellationSignal(this.getUser.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return {
        user: response.data,
      };
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Update the user
   * @param {String} token Valid access token
   * @param {String} name Updated name of the user
   * @param {String} lastName Updated lastname of the user
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Server response or null in case of unexpected error
   */
  updateUser: async function updateUser(token, name, lastName, cancel = false) {
    const url = '/authorized/user';
    const headers = { Authorization: `Bearer ${token}` };
    const data = {
      name,
      lastName,
    };

    const response = await api
      .request({
        url,
        method: 'PUT',
        headers,
        data,
        signal: this._getCancellationSignal(this.updateUser.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200 || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get list of courses
   * @param {String} token Valid access token
   * @param {Boolean} isFree Flag if courses should be free
   * @param {Boolean} isPurchased Flag if courses should be purchased by selected user
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sorts Sort list by field name with + or - at the end for select sort direction
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Courses data or null
   */
  // eslint-disable-next-line max-len
  getCourses: async function getCourses(
    token,
    isFree,
    isPurchased,
    page,
    search,
    size,
    sorts,
    cancel = false
  ) {
    const url = '/v2/user/courses';
    const headers = { Authorization: `Bearer ${token}` };
    const params = {
      isFree,
      isPurchased,
      page,
      search,
      size,
      sorts,
    };

    const response = await api
      .request({
        url,
        method: 'GET',
        headers,
        params,
        signal: this._getCancellationSignal(this.getCourses.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return response.data;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Subscribe current user to the course
   * @param {String} token Valid access token
   * @param {String} courseId Course ID
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Response or null in case of error
   */
  subscribeToCourse: async function subscribeToCourse(
    token,
    courseId,
    cancel = false
  ) {
    const url = `/v2/user/courses/${courseId}/subscribe`;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await api
      .request({
        url,
        method: 'PUT',
        headers,
        signal: this._getCancellationSignal(
          this.subscribeToCourse.name,
          cancel
        ),
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response.status)) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get specified course by ID
   * @param {String} token Valid access token
   * @param {String} courseID Сourse ID
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Response with course data or null
   */
  getCourseById: async function getCourseById(token, courseId, cancel = false) {
    const url = `v2/user/courses/${courseId}`;
    const headers = { Authorization: `Bearer ${token}` };

    const response = await api
      .request({
        url,
        method: 'GET',
        headers,
        signal: this._getCancellationSignal(this.getCourseById.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get videos of course specified by ID
   * @param {String} token Valid access token
   * @param {String} courseId Сourse ID
   * @param {Number} page Offset from the beginning of the selection
   * @param {String} search Search criteria (filter)
   * @param {Number} size Size of one page in items
   * @param {String} sorts Parameter name for sorting
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Videos data or null
   */
  getCourseVideos: async function getCourseVideos(
    token,
    courseId,
    page,
    search,
    size,
    sorts,
    cancel = false
  ) {
    const url = `/v2/user/courses/${courseId}/videos`;
    const headers = { Authorization: `Bearer ${token}` };
    const params = {
      page,
      search,
      size,
      sorts,
    };

    const response = await api
      .request({
        url,
        method: 'GET',
        headers,
        params,
        signal: this._getCancellationSignal(this.getCourseVideos.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get image for specified object
   * @param {String} token Valid access token
   * @param {String} objectId Object ID (now can be Course or Video ID)
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Image data or null
   */
  getImageByID: async function getCourseImage(token, objectId, cancel = false) {
    const url = `/pictures/${objectId}`;
    const headers = { Authorization: `Bearer ${token}` };

    const response = await api
      .request({
        url,
        method: 'GET',
        headers,
        signal: this._getCancellationSignal(this.getImageByID.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return response.data;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Get video with specified ID from parent course with specified ID
   * @param {String} token Valid access token
   * @param {String} videoId Video ID
   * @param {String} videoId Course ID
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Response with video data or null
   */
  getVideoById: async function getVideoById(
    token,
    videoId,
    courseId,
    cancel = false
  ) {
    const url = `/v2/user/courses/${courseId}/videos/${videoId}`;
    const headers = { Authorization: `Bearer ${token}` };

    const response = await api
      .request({
        url,
        method: 'GET',
        headers,
        signal: this._getCancellationSignal(this.getVideoById.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (response?.status === 200) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },

  /**
   * Change the user's password to the new one
   * @param {String} token Valid access token
   * @param {String} currentPassword User's current password
   * @param {String} newPassword User's new password
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Server response or null in case of unexpected error
   */
  changePassword: async function changePassword(
    token,
    currentPassword,
    newPassword,
    cancel = false
  ) {
    const url = '/authorized/user/password';
    const headers = { Authorization: `Bearer ${token}` };
    const data = {
      currentPassword,
      newPassword,
    };
    const response = await api
      .request({
        url,
        method: 'PUT',
        headers,
        data,
        signal: this._getCancellationSignal(this.changePassword.name, cancel),
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response?.status) || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },
  requestRecoveryCode: async function requestRecoveryCode(email) {
    const url = '/recovery/email';
    const data = {
      email,
    };
    const response = await api
      .request({
        url,
        method: 'POST',
        data,
      })
      .catch((error) => {
        new APIError(error);
        throw new Error('Что-то пошло не так. Попробуйте еще раз');
      });
  },
  recoverPasswordByEmail: async function recoverPasswordByEmail(
    email,
    code,
    password
  ) {
    const url = '/recovery/email';
    const data = {
      email,
      code,
      password,
    };
    const response = await api
      .request({
        url,
        method: 'PUT',
        data,
      })
      .catch((error) => new APIError(error));

    if (isResponse2xx(response?.status) || response?.status === 400) {
      return response;
    }

    showAPIError(url, response);
    return null;
  },
};

export default coursesAPI;
