/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

import styles from './Controls.module.scss';
import imgFullscreenLight from '../../../assets/icons/themes/light/fullscreen.svg';
import imgFullscreenDark from '../../../assets/icons/themes/dark/fullscreen.svg';
import imgSettingsLight from '../../../assets/icons/themes/light/settings.svg';
import imgSettingsDark from '../../../assets/icons/themes/dark/settings.svg';
import imgVolumeOnLight from '../../../assets/icons/themes/light/volume.svg';
import imgVolumeOnDark from '../../../assets/icons/themes/dark/volume.svg';
import imgVolumeOffLight from '../../../assets/icons/themes/light/volume-off.svg';
import imgVolumeOffDark from '../../../assets/icons/themes/dark/volume-off.svg';

import { prepareTimeFromSeconds } from '../../../utils/strings';
import { joinClasses } from '../../../utils/helper';
import useTheme from '../../../hook/useTheme';
import useOutClick from '../../../hook/useOutClick';
import useVideoControls from '../../../hook/useVideoControls';
import { AUTO_LEVEL_VALUE } from '../../../hoc/VideoControlsProvider';

import VideoLine from './VideoLine/VideoLine';
import { themesEnum } from '../../../hoc/ThemeProvider';

// List of fixed speed steps
const SPEED_STEPS = [0.5, 0.75, 1, 1.25, 1.5, 2];

// List of available settings menues
export const ActiveMenuEnum = {
  none: null,
  speed: 'speed',
  quality: 'quality',
};

function Controls({ className }) {
  const [isSettings, setIsSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(ActiveMenuEnum.none);
  const [skipped5SF, setSkipped5SF] = useState(false);
  const [skipped5SB, setSkipped5SB] = useState(false);
  const [skipped10SF, setSkipped10SF] = useState(false);
  const [skipped10SB, setSkipped10SB] = useState(false);

  const { theme } = useTheme();

  const {
    isPlaying,
    setIsPlaying,
    volume,
    setVolume,
    isMuted,
    setIsMuted,
    speed,
    setSpeed,
    level,
    setLevel,
    levelsList,
    durationSec,
    playedSec,
    setTargetSec,
    isFullscreen,
    setIsFullscreen,
  } = useVideoControls();

  const refPlayer = useRef();

  const refSettings = useOutClick(() => {
    if (isSettings) {
      setIsSettings(false);
    }
  });

  // Focus on video player on init
  useEffect(() => {
    refPlayer?.current?.focus();
  }, []);

  const timeWidthStyle = useMemo(() => {
    if (durationSec >= 3600) {
      return styles.width3;
    }
    if (durationSec >= 600) {
      return styles.width2;
    }
    return styles.width1;
  }, [durationSec]);

  useEffect(() => {
    if (skipped5SF) {
      setTimeout(() => {
        setSkipped5SF(false);
      }, 100);
    }
    if (skipped5SB) {
      setTimeout(() => {
        setSkipped5SB(false);
      }, 100);
    }
    if (skipped10SF) {
      setTimeout(() => {
        setSkipped10SF(false);
      }, 100);
    }
    if (skipped10SB) {
      setTimeout(() => {
        setSkipped10SB(false);
      }, 100);
    }
  }, [skipped5SF, skipped5SB, skipped10SF, skipped10SB]);

  // Global keydown processing to control player
  useEffect(() => {
    function handleKeys(e) {
      const VOLUME_STEP = 0.05; // 5% same as YouTube
      const REWIND_STEP = 5; // Seek backward/forward 5 seconds
      switch (e.keyCode) {
        case 70: // F
          setIsFullscreen(!isFullscreen);
          break;
        case 32: // Space
          e.preventDefault();
          setIsPlaying(!isPlaying);
          break;
        case 37: // Arrow Left
          setTargetSec(playedSec - REWIND_STEP);
          setSkipped5SB(true);
          break;
        case 39: // Arrow Right
          setTargetSec(playedSec + REWIND_STEP);
          setSkipped5SF(true);
          break;
        case 38: // Arrow Up
          if (document.activeElement === refPlayer.current) {
            e.preventDefault();
            setVolume(volume + VOLUME_STEP < 1 ? volume + VOLUME_STEP : 1);
          }
          break;
        case 40: // Arrow Down
          if (document.activeElement === refPlayer.current) {
            e.preventDefault();
            setVolume(volume - VOLUME_STEP > 0 ? volume - VOLUME_STEP : 0);
          }
          break;
        case 36: // Home
          if (document.activeElement === refPlayer.current) {
            e.preventDefault();
            setTargetSec(0);
          }
          break;
        case 74: // J
          // Временное решение в будущем должны добавиться иконки с пролистыванием на 10 секунд
          setTargetSec(playedSec - REWIND_STEP);
          setSkipped10SB(true);
          break;
        case 75: // K
          e.preventDefault();
          setIsPlaying(!isPlaying);
          break;
        case 76: // L
          // Временное решение в будущем должны добавиться иконки с пролистыванием на 10 секунд
          setTargetSec(playedSec + REWIND_STEP);
          setSkipped10SF(true);
          break;
        default:
        // console.log(e.keyCode);
      }

      if (
        (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)
      ) {
        // 0-9 keys
        // if (document.activeElement === refPlayer.current) {
        const posPercent = Number(e.key) / 10;
        setTargetSec(Math.round(posPercent * durationSec));
        // }
      }
    }

    document.addEventListener('keydown', handleKeys);
    return () => {
      document.removeEventListener('keydown', handleKeys);
    };
  }, [isPlaying, isFullscreen, volume, playedSec]);

  const imgFullscreen = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return imgFullscreenDark;
      case themesEnum.LIGHT:
        return imgFullscreenLight;
      default:
        return null;
    }
  }, [theme]);

  const imgSettings = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return imgSettingsDark;
      case themesEnum.LIGHT:
        return imgSettingsLight;
      default:
        return null;
    }
  }, [theme]);

  const imgVolumeOn = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return imgVolumeOnDark;
      case themesEnum.LIGHT:
        return imgVolumeOnLight;
      default:
        return null;
    }
  }, [theme]);

  const imgVolumeOff = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return imgVolumeOffDark;
      case themesEnum.LIGHT:
        return imgVolumeOffLight;
      default:
        return null;
    }
  }, [theme]);

  const handleOverlayClick = (e) => {
    refPlayer?.current?.focus();
    // Trigger overlay click only in case of direct click
    if (e.target === e.currentTarget) {
      switch (e.detail) {
        case 2: // double-click
          setIsFullscreen(!isFullscreen);
          break;
        default:
          break;
      }
      // Change playing state if settings not opened only
      if (!isSettings) {
        setIsPlaying(!isPlaying);
      }
    }
  };

  const handleOnPlayPause = (e) => {
    // Change playing state if settings not opened only
    if (!isSettings) {
      setIsPlaying(!isPlaying);
      console.log('handleOnPlayPause', e);
      e.stopPropagation();
    }
  };

  const handleVolume = (e) => {
    if (e.target.value) {
      setVolume(Number(e.target.value));
    } else {
      setVolume(0);
    }
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const toggleSettingsMenu = (e) => {
    setIsSettings(!isSettings);
    setActiveMenu(ActiveMenuEnum.none);
    e.stopPropagation();
  };

  const handleOpenMenu = (e) => {
    setActiveMenu(e.currentTarget.getAttribute('data-menu'));
    e.stopPropagation();
  };

  const handleCloseMenu = (e) => {
    setActiveMenu(ActiveMenuEnum.none);
    e.stopPropagation();
  };

  const handleSelectSpeed = (e) => {
    const value = Number(e.currentTarget.getAttribute('data-value'));
    setSpeed(value);
    setActiveMenu(ActiveMenuEnum.none);
    e.stopPropagation();
  };

  const handleSelectQuality = (e) => {
    const levelValue = Number(e.currentTarget.getAttribute('data-value'));
    setLevel(levelValue);
    setActiveMenu(ActiveMenuEnum.none);
    e.stopPropagation();
  };

  return (
    <div
      className={joinClasses([styles.controls, className])}
      onClick={handleOverlayClick}
      draggable={false}
      tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      ref={refPlayer}
    >
      <div
        className={joinClasses([
          styles.playBtn,
          isPlaying ? styles.play : styles.pause,
        ])}
        onClick={handleOnPlayPause}
        alt="Воспроизведение/Пауза"
      />
      <div
        className={joinClasses([styles.timeSkip5F, skipped5SF && styles.skip])}
      />
      <div
        className={joinClasses([styles.timeSkip5B, skipped5SB && styles.skip])}
      />
      <div
        className={joinClasses([styles.timeSkip10F, skipped10SF && styles.skip])}
      />
      <div
        className={joinClasses([styles.timeSkip10B, skipped10SB && styles.skip])}
      />

      <div className={styles.videoLineContainer}>
        <div
          className={joinClasses([
            styles.smallPlayBtn,
            isPlaying ? styles.pause : styles.play,
          ])}
          onClick={handleOnPlayPause}
          alt="Воспроизведение/Пауза"
        />
        <div className={styles.time}>
          <span className={joinClasses([styles.current, timeWidthStyle])}>
            {prepareTimeFromSeconds(playedSec)}
          </span>
          <span className={styles.delimiter} />
          <span className={styles.total}>
            {prepareTimeFromSeconds(durationSec)}
          </span>
        </div>
        <div className={styles.videoLine}>
          <VideoLine />
        </div>

        <div className={styles.buttons}>
          <div
            className={joinClasses([
              styles.btnContainer,
              styles.settingsBtn,
              isSettings ? styles.active : '',
            ])}
            onClick={toggleSettingsMenu}
          >
            <img src={imgSettings} alt="Настройки" />

            {isSettings && (
              <div className={styles.configMenuWrapper} ref={refSettings}>
                <div className={styles.configMenu}>
                  <div
                    className={styles.configLine}
                    data-menu={ActiveMenuEnum.speed}
                    onClick={handleOpenMenu}
                  >
                    <div className={styles.configOption}>Скорость:</div>
                    <div className={styles.configValue}>&times;{speed}</div>
                  </div>
                  <div
                    className={styles.configLine}
                    data-menu={ActiveMenuEnum.quality}
                    onClick={handleOpenMenu}
                  >
                    <div className={styles.configOption}>Качество:</div>
                    <div className={styles.configValue}>
                      {level >= 0 ? levelsList[level] : 'Авто'}
                    </div>
                  </div>
                </div>

                <div
                  className={joinClasses([
                    styles.configSubmenu,
                    activeMenu === ActiveMenuEnum.speed ? styles.active : '',
                  ])}
                >
                  <div className={styles.holder}>
                    <div
                      key="back"
                      className={styles.configLine}
                      onClick={handleCloseMenu}
                    >
                      &lt; Скорость
                    </div>
                    {SPEED_STEPS.map((value) => (
                      <div
                        key={value}
                        className={joinClasses([
                          styles.configLine,
                          speed === value ? styles.active : '',
                        ])}
                        data-value={value}
                        onClick={handleSelectSpeed}
                      >
                        &times;&thinsp;{value}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className={joinClasses([
                    styles.configSubmenu,
                    activeMenu === ActiveMenuEnum.quality ? styles.active : '',
                  ])}
                >
                  <div className={styles.holder}>
                    <div
                      key="back"
                      className={styles.configLine}
                      onClick={handleCloseMenu}
                    >
                      &lt; Качество
                    </div>
                    {levelsList.map((levelName, index) => (
                      <div
                        key={levelName}
                        data-value={index}
                        className={joinClasses([
                          styles.configLine,
                          level === index ? styles.active : '',
                        ])}
                        onClick={handleSelectQuality}
                      >
                        {levelName}
                      </div>
                    ))}
                    <div
                      key="auto"
                      className={joinClasses([
                        styles.configLine,
                        level === AUTO_LEVEL_VALUE ? styles.active : '',
                      ])}
                      data-value={AUTO_LEVEL_VALUE}
                      onClick={handleSelectQuality}
                    >
                      Авто
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={joinClasses([
              styles.btnContainer,
              styles.volumeBtn,
              isMuted ? styles.volumeMuted : '',
            ])}
          >
            <img
              src={isMuted || !volume ? imgVolumeOff : imgVolumeOn}
              alt="Выключить звук"
              onClick={handleMute}
            />
            <div className={styles.volumeMenuWrapper}>
              <div className={styles.volumeMenu}>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.05"
                  value={volume}
                  onChange={handleVolume}
                />
              </div>
            </div>
          </div>

          <div className={styles.btnContainer} onClick={toggleFullscreen}>
            <img src={imgFullscreen} alt="Полный экран" />
          </div>
        </div>
      </div>
    </div>
  );
}

Controls.propTypes = {
  className: PropTypes.string,
};

Controls.defaultProps = {
  className: '',
};

export default Controls;
