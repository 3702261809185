import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

import { joinClasses } from '../../utils/helper';

export const ButtonTypeEnum = {
  default: styles.default,
  muted: styles.muted,
  colorful: styles.colorful,
};

// Equivalent to the html target attribute
export const TargetEnum = {
  self: '_self',
  blank: '_blank',
};

/**
 * Simple button
 * @component
 */
function Button({
  children,
  type,
  className,
  onClick,
  onKeyDown,
  tabIndex,
  to,
  target,
}) {
  const cls = joinClasses([styles.button, className, type]);

  const handleClick = (e) => {
    if (type !== ButtonTypeEnum.muted) {
      onClick(e);
    }
    if (to) {
      switch (target) {
        case TargetEnum.self:
          window.location.href = to;
          break;
        case TargetEnum.blank:
          window.open(to, '_blank', 'noreferrer');
          break;
        default:
      }
    }
  };

  return (
    <div
      className={cls}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
}

Button.propTypes = {
  // Button title
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // Button type
  type: PropTypes.oneOf(Object.values(ButtonTypeEnum)),
  // Custom class name
  className: PropTypes.string,
  // Callback function on click event
  onClick: PropTypes.func,
  // Callback function on kepdown event
  onKeyDown: PropTypes.func,
  // Focus index (specifies the tab order of an element)
  tabIndex: PropTypes.number,
  // Optional link
  to: PropTypes.string,
  // Optional target attribute for link (similar to to html)
  target: PropTypes.oneOf(Object.values(TargetEnum)),
};

Button.defaultProps = {
  type: ButtonTypeEnum.default,
  className: '',
  onClick: () => null,
  onKeyDown: () => null,
  tabIndex: 0,
  to: '',
  target: TargetEnum.self,
};

export default Button;
