import PropTypes from 'prop-types';

export const PictureShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  externalEntity: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thumborId: PropTypes.string.isRequired,
  alternateText: PropTypes.string.isRequired,
  tinyImage: PropTypes.string.isRequired,
  dominantColor: PropTypes.string,
  instances: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  })),
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
});
