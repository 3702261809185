import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './Logo.module.scss';
import logoLight from '../../assets/images/themes/light/logo_drstar.png';
import logoLightS from '../../assets/images/themes/light/logo_drstar_s.png';
import logoDark from '../../assets/images/themes/dark/logo_drstar.png';
import logoDarkS from '../../assets/images/themes/dark/logo_drstar_s.png';

import useTheme from '../../hook/useTheme';
import { themesEnum } from '../../hoc/ThemeProvider';
import { joinClasses } from '../../utils/helper';

export const LogoSizeEnum = {
  SMALL: 'small',
  FULL: 'full',
};

/**
 * Logo
 * @component
 */
function Logo({ className, size }) {
  const { theme } = useTheme();

  const logoImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return size === LogoSizeEnum.SMALL ? logoDarkS : logoDark;
      case themesEnum.LIGHT:
        return size === LogoSizeEnum.SMALL ? logoLightS : logoLight;
      default:
        return null;
    }
  }, [theme, size]);

  return (
    <div className={joinClasses([styles.logo, className])}>
      <img src={logoImg} alt="Doctor Star School" />
    </div>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(LogoSizeEnum)),
};

Logo.defaultProps = {
  className: '',
  size: LogoSizeEnum.FULL,
};

export default Logo;
