import PropTypes from 'prop-types';
import useCourses from '../../hook/useCourses';
import styles from './TextHighlighter.module.scss';

function TextHighlighter({
  text,
  search,
  classes,
}) {
  const { searchInProgress } = useCourses();
  const startPosition = text.toLowerCase().indexOf(search.toLowerCase());
  const endPosition = startPosition + search.length;
  if (searchInProgress || startPosition < 0 || !endPosition) {
    return <div className={[...classes]}>{text}</div>;
  }
  const [highlightedText, left, right] = [
    text.slice(startPosition, endPosition),
    text.slice(0, startPosition),
    text.slice(endPosition, text.length),
  ];

  return (
    <div className={classes.join(' ')}>
      <span>{left}</span>
      <span className={styles.textHighlighter}>{highlightedText}</span>
      <span>{right}</span>
    </div>
  );
}

TextHighlighter.propTypes = {
  text: PropTypes.string,
  search: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
};

TextHighlighter.defaultProps = {
  text: '',
  search: '',
  classes: [],
};

export default TextHighlighter;
