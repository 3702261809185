import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './CoursesNewTile.module.scss';
import stubImgLight from '../../../assets/images/themes/light/course_stub.png';
import stubImgDark from '../../../assets/images/themes/dark/course_stub.png';

import { ImageParamsEnum, ImageSizeEnum } from '../../../utils/settings';
import { joinClasses } from '../../../utils/helper';
import useTheme from '../../../hook/useTheme';

import { CourseShape } from '../../../apis/models/course';
import { ImageShape } from '../../../apis/models/image';
import { buildRoute as buildCourseRoute } from '../../../containers/CoursePage/CoursePage.Route';
import { themesEnum } from '../../../hoc/ThemeProvider';

function CoursesNewTile({ course, image, className }) {
  const { theme } = useTheme();

  const cls = joinClasses([styles.tile, className]);
  const imgParams = ImageParamsEnum[ImageSizeEnum.thumb];

  const tileImg = useMemo(() => {
    const imgLink = image?.instances?.[ImageSizeEnum.thumb]?.url;
    if (imgLink) {
      return imgLink;
    }
    switch (theme) {
      case themesEnum.DARK:
        return stubImgDark;
      case themesEnum.LIGHT:
      default:
        return stubImgLight;
    }
  }, [image]);

  const handleImageError = (e) => {
    e.target.src = stubImgLight;
  };

  return (
    <Link to={buildCourseRoute(course)} className={cls}>
      <div className={styles.image}>
        <img
          src={tileImg}
          alt={course.title}
          width={imgParams.width}
          height={imgParams.height}
          title={course.title}
          className={styles.courseImage}
          onError={handleImageError}
        />
      </div>
      <div className={styles.description}>
        <div className={styles.title} title={course.title}>
          {course.title}
        </div>
        <div className={styles.content} title={course.shortDescription}>
          {course.shortDescription}
        </div>
      </div>
    </Link>
  );
}

CoursesNewTile.propTypes = {
  // Course data structure
  course: CourseShape.isRequired,
  image: ImageShape,
  className: PropTypes.string,
};

CoursesNewTile.defaultProps = {
  className: '',
  image: null,
};

export default CoursesNewTile;
