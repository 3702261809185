import React from 'react';
import PropTypes from 'prop-types';

import styles from './Dropdown.module.scss';
import useOutClick from '../../hook/useOutClick';

/**
 * Stateless dropdown
 * @component
 */
function Dropdown({
  title,
  onRequestClose,
  className,
  children,
}) {
  const ref = useOutClick((event) => {
    onRequestClose(event);
  });

  const cls = styles.dropdown + (className ? ` ${className}` : '');

  return (
    <div className={cls} ref={ref}>
      {title && (
      <div className={styles.title}>{title}</div>
      )}
      <div className={styles.items}>{children}</div>
    </div>
  );
}

Dropdown.propTypes = {
  // Dropdown title
  title: PropTypes.string.isRequired,
  // Request for close function (event passed)
  onRequestClose: PropTypes.func,
  // Custom class name (additional)
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Dropdown.defaultProps = {
  onRequestClose: () => null,
  className: '',
};

export default Dropdown;
