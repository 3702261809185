import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './CoursesGridTile.module.scss';

import { ImageParamsEnum, ImageSizeEnum } from '../../../utils/settings';

import stubImgLight from '../../../assets/images/themes/light/course_stub.png';
import stubImgDark from '../../../assets/images/themes/dark/course_stub.png';

import useTheme from '../../../hook/useTheme';
import { themesEnum } from '../../../hoc/ThemeProvider';

function CoursesGridTileSkeleton() {
  const { theme } = useTheme();

  const imgParams = ImageParamsEnum[ImageSizeEnum.thumb];

  const stubImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return stubImgDark;
      case themesEnum.LIGHT:
      default:
        return stubImgLight;
    }
  }, [theme]);

  return (
    <div className={styles.tile}>
      <div className={styles.image}>
        <img
          src={stubImg}
          alt="Курс загружается..."
          width={imgParams.width}
          height={imgParams.height}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.title}><Skeleton /></div>
        <div className={styles.description}><Skeleton count={3} /></div>
      </div>
    </div>
  );
}

export default CoursesGridTileSkeleton;
