/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './PrivacyPage.module.scss';

import Logo from '../../components/Logo/Logo';

/**
 * Terms page
 * @component
 */
function PrivacyPage() {
  return (
    <div className={styles.holder}>
      <div className={styles.contentWrapper}>
        <div className={styles.logo}><Logo /></div>

        <h1>Политика обработки персональных данных</h1>

        <p className={styles.leftAligned}>версия документа от «22» декабря 2023 г.</p>

        <p>Настоящая Политика обработки персональных данных (далее — Политика) описывает, как Общество с ограниченной ответственностью «КИТ МЕД» (также в тексте, «мы», «нас», «наш») обрабатывает персональные данные на сайте <Link to="https://school.drstar.ru">school.drstar.ru</Link></p>

        <h2>Термины, которые вы можете встретить в нашей Политике</h2>

        <p><span className={styles.emphasized}>Персональные данные</span> — любая информация, которая прямо или косвенно относится к вам или позволяет вас определить.</p>

        <p><span className={styles.emphasized}>Оператор персональных данных</span> — юридическое или физическое лицо, осуществляющие обработку персональных данных. При посещении нашего сайта мы являемся оператором ваших персональных данных.</p>

        <p><span className={styles.emphasized}>Обработка персональных данных</span> — совершение любых действий с персональными данными.</p>

        <h2>Ваши права при обработке персональных данных</h2>

        <p>В соответствии с Федеральным законом от 27.07.2006 No 152-ФЗ от «О персональных данных», вы имеете право:</p>

        <ol>
          <li>на доступ к персональным данным;</li>
          <li>на уточнение персональных данных;</li>
          <li>на блокирование и удаление персональных данных;</li>
          <li>на обжалование наших действий или нашего бездействия;</li>
          <li>на обжалование решений, принятых на основании исключительно автоматизированной обработки их персональных данных;</li>
          <li>на отзыв согласия на обработку персональных данных.</li>
        </ol>

        <h2>Как вы можете реализовать свои права?</h2>

        <p>Написать запрос на наш адрес 109544, Москва, Бульвар Энтузиастов, д.2, указав в нем сведения о документе, удостоверяющем вашу личность или личность вашего представителя (тип документа, серия и номер, кем и когда выдан), ваше ФИО или ФИО представителя, информацию о наших взаимоотношениях с вами, в ходе которых мы получили ваши персональные данные. Пожалуйста, не забудьте подписать свой запрос.</p>

        <p>Вы можете отправить запрос по электронной почте <Link to="mailto:info@citmed.ru">info@citmed.ru</Link>, в этом случае запрос должен быть в форме электронного документа, подписанного в соответствии с положениями законодательства Российской Федерации об электронной подписи.</p>

        <p>Также вы можете позвонить по телефону <Link to="tel:+74952259955">+7 (495) 225-99-55</Link>.</p>

        <h2>Для чего, как долго, на каком основании и какие ваши данные мы обрабатываем</h2>

        <p>Для достижения перечисленных ниже целей мы будем собирать, записывать, систематизировать, накапливать, хранить, уточнять (обновлять, изменять), извлекать, использовать, передавать (предоставлять, обеспечивать доступ), блокировать, удалять, уничтожать персональные данные.</p>

        <h2>Регистрация пользователя</h2>

        <p>ФИО; e-mail-адрес; номер телефона.</p>

        <p>Обрабатываемые в рамках указанной цели персональные данные не относятся к специальным категориям или биометрическим в соответствии со ст. 10–11 152-ФЗ и обрабатываются автоматизированным способом.</p>

        <p>Основанием для обработки ваших персональных данных в этом процессе является согласие на обработку персональных данных.</p>

        <p>Мы уничтожим ваши персональные данные в течение 30 дней, в случаях:</p>

        <ol>
          <li>если в регистрации аккаунта вам будет отказано / аккаунт не будет активирован</li>
          <li>неиспользования аккаунта более 365 дней подряд</li>
          <li>одностороннего отказа ООО «КИТ МЕД» от исполнения положений Пользовательского соглашения в отношении сервиса Doctor Star School.</li>
        </ol>

        <p>в соответствии с ч. 4-5 ст. 21 152-ФЗ, в зависимости от того, что произойдет раньше.</p>

        <h2>Аналитика посещений нашего сайта</h2>

        <p>Мы хотим, чтобы вы могли использовать наш сайт так, как мы его задумали. Поэтому для его корректной работы мы используем cookie-файлы, которые собираются в отношении Пользователей.</p>

        <p>Что такое cookie-файлы?<br />Файлы cookie — это небольшие файлы, обычно состоящие из букв и цифр, загружаемые на устройство, когда вы обращаетесь к определенным веб-сайтам. Файлы cookie позволяют веб- сайту распознавать ваше устройство и отображать наш сайт в соответствии с вашими предпочтениями.</p>

        <p>Данные, собираемые с помощью cookie-файлов, не относятся к специальным категориям или биометрическим в соответствии со ст. 10–11 152-ФЗ и обрабатываются автоматизированным способом.</p>

        <p>Основанием для обработки ваших данных в этом случае будет согласие на обработку персональных данных, предоставляемого Пользователем сайта путем совершения конклюдентных действий — продолжение пользования сайтом.</p>

        <p>Если вы не хотите, чтобы мы собирали ваши данные с помощью cookie-файлов вы можете отключить их сбор в настройках своего браузера.</p>

        <h2>Безопасность данных</h2>

        <p>Персональные данные, которые мы собираем и храним, считаются конфиденциальной информацией. Они защищены от потери, изменения или несанкционированного доступа согласно законодательству Российской Федерации в области персональных данных. Для этого мы применяем технические средства и организационные меры в соответствии со ст. 18, 18.1, 19 152-ФЗ.</p>
      </div>
    </div>
  );
}

PrivacyPage.propTypes = {
};

export default PrivacyPage;
