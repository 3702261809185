import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './VideoListCard.module.scss';

import { joinClasses } from '../../../utils/helper';

function VideoListCardSkeleton() {
  const getBody = () => (
    <>
      <div className={styles.videoText}>
        <h4 className={styles.videoHeading}><Skeleton count={3} /></h4>
      </div>
      <div className={styles.videoCover}>
        <Skeleton height="100%" />
      </div>
    </>
  );

  return (
    <div className={joinClasses([styles.videoCard, styles.disabled])}>
      {getBody()}
    </div>
  );
}

export default VideoListCardSkeleton;
