// List of available methods for logging in
export const LoginTypeEnum = {
  username: 'Username',
  phone: 'Phone',
  email: 'Email',
  requestPassword: 'RequestPassword',
  enterTemporaryPassword: 'EnterTemporaryPassword',
  createNewPassword: 'CreateNewPassword',
  passwordUpdated: 'PasswordUpdated',
};

// List of available sorts for courses
export const CoursesSortEnum = {
  usersCountAsc: 'usersCount+',
  usersCountDesc: 'usersCount-',
  createdAtAsc: 'createdAt+',
  createdAtDesc: 'createdAt-',
  purchasedAtAsc: 'purchasedAt+',
  purchasedAtDesc: 'purchasedAt-',
};

/**
 * Parse JWT and return its payload
 * (could use jwt-decode module for that)
 * @param {String} token Token to parse
 * @returns Object with payload of JWT
 */
export const getJwtPayload = (token) => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window.atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

/**
 * Check to see if the expiration time has come (offset is aplied to the test time)
 * @param {Number} exp Time from the start of Epoch in seconds
 * @returns True if time has come
 */
export const checkExpired = (exp) => {
  const EXPIRATION_OFFSET = 10; // Shift offsset to earlier time
  const expDate = new Date((exp - EXPIRATION_OFFSET) * 1000);
  const nowDate = new Date();
  return nowDate > expDate;
};

/**
 * Generate pseudorandompassword
 * @returns Password String
 */
export const generatePwd = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ`~-_=+[{]}\\|;:\'",<.>/?';
  const pwdLength = 32;
  let pwd = '';
  for (let i = 0; i <= pwdLength; i += 1) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    pwd += chars.substring(randomNumber, randomNumber + 1);
  }
  return pwd;
};

/**
 * Returns generic username for user registered by phone number
 * @param {Number} phoneNumber User's phone number
 * @returns Username string
 */
export const getUsernameByPhone = (phoneNumber) => `user_${phoneNumber}`;

export const joinClasses = (scssClasses) => `${scssClasses.join(' ')}`;

/**
 * Merge two object arrays and keep unique the key
 * Skipping objects with same key from second array
 **Complexity: o(n), use with caution
 * @param {Array} array1 Objects array
 * @param {Array} array2 Additional objects array
 * @param {String} attr Attribute name for uniqueness check
 * @returns New array merged from two original arrays
 */
export const uniqueMerge = (array1, array2, attr) => {
  const array2Upd = array2.filter(
    (item2) => typeof array1.find((item1) => item1?.[attr] === item2?.[attr]) === 'undefined',
  );
  return [...array1, ...array2Upd];
};

/**
 * Function compares current date to start and expiration date of video
 * and returns object with 2 fields: isValid and errorText
 * @param start
 * @param end
 * @returns {{errorText: string, isValid: boolean}}
 */
export const checkIfVideoExpirationIsValid = (start, end) => {
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();
  const currentDate = new Date().getTime();
  let validityInfo = {
    isValid: true,
    errorText: '',
  };

  if (currentDate < startDate) {
    validityInfo = {
      isValid: false,
      errorText: `Доступ к видео откроется в ${new Date(start).toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' }).substring(11)} по МСК ${new Date(start).toLocaleString('ru-RU').substring(0, 10)}`,
    };
  } else if (currentDate > endDate) {
    validityInfo = {
      isValid: false,
      errorText: `Доступ к видео закрыт ${new Date(end).toLocaleString('ru-RU').substring(0, 10)}`,
    };
  }
  return validityInfo;
};

/**
 * Function to trim strings. Accept a string and removes double spaces, space in the beginning and at the end.
 * @param str
 * @returns {string}
 */

export const removeDoubleSpaces = (str) => {
  let strResult = '';
  let prev = '';
  const strCopy = [...str];

  while (strCopy.length) {
    const chr = strCopy.shift();
    strResult += prev + chr !== '  ' ? chr : '';
    prev = chr;
  }

  return strResult.trim();
};
