// List of available profile actions
export const ProfileActionEnum = {
  changePassword: 'changepassword',
  changePersonal: 'personal',
};

export const getRoutes = () => ['/profile', '/profile/:profileAction'];
export const buildRoute = (action) => {
  if (action) {
    return `/profile/${action}`;
  }
  return '/profile';
};
