import { APIError } from './configs/axiosUtils';
import api from './configs/axiosConfig';
import coursesAPI from './coursesAPI';

const baseUrl = '/v2/user/streams';

export const streamsAPI = {
  fetchStreams: async (token, pagination) => {
    try {
      const streams = (await api.get(`${baseUrl}`, {
        params: pagination,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      const coverMap = new Map();

      for (const stream of streams.items) {
        try {
          const cover = await coursesAPI.getImageByID(token, stream.id);
          coverMap.set(stream.id, cover);
        } catch (e) {
          console.error(`Stream with id: ${stream.id} has no cover, error: `, e);
        }
      }

      return {
        pagination: streams.pagination,
        items: streams.items.map(s => ({
          ...s,
          cover: coverMap.get(s.id)
        }))
      };
    } catch (e) {
      new APIError(e);
    }
  },
  fetchStream: async (token, id) => {
    try {
      const stream = (await api.get(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      let cover;

      try {
        cover = await coursesAPI.getImageByID(token, stream.id);
      } catch (e) {
        console.error(`Stream with id: ${stream.id} has no cover, error: `, e);
      }

      const managersCoverMap = new Map();
      const speakersCoverMap = new Map();

      for (const manager of stream.managers) {
        const managerCover = await coursesAPI.getImageByID(token, manager.id);
        managersCoverMap.set(manager.id, managerCover);
      }

      for (const speaker of stream.speakers) {
        const managerCover = await coursesAPI.getImageByID(token, speaker.id);
        speakersCoverMap.set(speaker.id, managerCover);
      }

      return {
        ...stream,
        managers: stream.managers.map(m => ({
          ...m,
          cover: managersCoverMap.get(m.id)
        })),
        speakers: stream.speakers.map(s => ({
          ...s,
          cover: speakersCoverMap.get(s.id)
        })),
        cover
      };
    } catch (e) {
      new APIError(e);
    }
  },
  fetchStreamSpecs: async (token) => {
    try {
      return (await api.get(`${baseUrl}/filters`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;
    } catch (e) {
      new APIError(e);
    }
  }
};
