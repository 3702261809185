import { useContext } from 'react';
import { VideoControlsContext } from '../hoc/VideoControlsProvider';

export default function useVideoControls() {
  const context = useContext(VideoControlsContext);
  if (context === null) {
    throw new Error('useVideoControls must be used within a VideoControlsProvider');
  }
  return context;
}
