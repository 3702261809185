import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './BlankTemplate.module.scss';

import { SITE_NAME } from '../../utils/settings';
import useError from '../../hook/useError';
import ErrorHttpPage from '../../containers/ErrorHttpPage/ErrorHttpPage';

/**
 * Blank page layout
 * @component
 */
function BlankTemplate({ title, children }) {
  const { error } = useError();

  useEffect(() => {
    document.title = `${title} | ${SITE_NAME}`;
  }, [title]);

  return (
    <div className={styles.blankLayout}>
      {(error.code === 0 && (
        <div>
          {children}
        </div>
      )) || (
        <ErrorHttpPage code={error.code} headerFix={false} />
      )}
    </div>
  );
}

BlankTemplate.propTypes = {
  // Page title for the browser tab
  title: PropTypes.string.isRequired,
  // Content of the layout
  children: PropTypes.node,
};

BlankTemplate.defaultProps = {
  children: '',
};

export default BlankTemplate;
