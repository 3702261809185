import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const VideoShape = PropTypes.shape({
  id: PropTypes.string,
  courseId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.number,
  startTime: PropTypes.string,
  expirationTime: PropTypes.string,
  link: PropTypes.string,
});
