export const SITE_NAME = 'Dr.Star School';

export const COURSES_NEW_ELEMENTS_COUNT = 3;
export const COURSES_HOME_ELEMENTS_COUNT = 6;
export const COURSES_MYCOURSES_ELEMENTS_COUNT = 6;
export const COURSES_FREE_ELEMENTS_COUNT = 6;

export const CODE_REQUEST_TIMEOUT_MS = 60 * 1000;

export const CODE_REQUEST_LIFETIME_MS = 5 * 60 * 1000;

export const PHONE_SUPPORTED_COUNTRIES = [
  'am',
  'az',
  'by',
  'bg',
  'ge',
  'de',
  'il',
  'kz',
  'kg',
  'kr',
  'md',
  'pl',
  'ru',
  'tj',
  'ua',
  'us',
  'uz',
];

export const ImageSizeEnum = {
  full: 0,
  thumb: 1,
  mini: 2,
};

export const ImageParamsEnum = {
  [ImageSizeEnum.full]: {
    width: 1920,
    height: 1080,
  },
  [ImageSizeEnum.thumb]: {
    width: 480,
    height: 270,
  },
  [ImageSizeEnum.mini]: {
    width: 80,
    height: 45,
  },
};
