import PropTypes from 'prop-types';
import styles from '../StreamPage.module.scss';
import { Schedule } from './Schedule';
import { VideoSection } from './VideoSection';
import { StreamShape } from '../../../apis/models/stream';

export function MainSection({
  stream,
  isStreamLoading,
}) {
  const hasPeoples = stream?.speakers?.length > 0 || stream?.managers?.length > 0;
  return (
    <div className={hasPeoples ? styles.panelMain : styles.fullWidthPanel}>
      <VideoSection stream={stream} isStreamLoading={isStreamLoading} />
      <Schedule stream={stream} isStreamLoading={isStreamLoading} />
    </div>
  );
}

MainSection.propTypes = {
  stream: StreamShape.isRequired,
  isStreamLoading: PropTypes.bool.isRequired
};

MainSection.defaultProps = {};
