// export const getRoute = () => '/course/:courseId/video/:videoId';
export const getRoutes = () => ['/:courseId/:videoId', '/mycourses/:courseId/:videoId'];

export const buildRoute = (course, video) => {
  if (course.isPurchased) {
    return `/mycourses/${course.id}/${video.id}`;
  }
  return `/${course.id}/${video.id}`;
};

// export const buildRoute = (videoId, courseId) => `/course/${courseId}/video/${videoId}`;
