/* eslint-disable arrow-body-style */
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useLocation, useNavigate } from 'react-router-dom';
import { coursesAPI } from '../apis/coursesAPI';
import useAuth from '../hook/useAuth';
import useError from '../hook/useError';
import { CoursesSortEnum, uniqueMerge } from '../utils/helper';
import {
  COURSES_HOME_ELEMENTS_COUNT,
  COURSES_MYCOURSES_ELEMENTS_COUNT,
  COURSES_NEW_ELEMENTS_COUNT,
} from '../utils/settings';

export const CoursesContext = createContext(null);

export function CoursesProvider({ children }) {
  const { user, getAccess, setUserNull } = useAuth();
  const { error } = useError();
  const { pathname } = useLocation();

  const [course, setCourse] = useState(null);

  const [courses, setCourses] = useState([]);
  const [coursesPagination, setCoursesPagination] = useState({});

  const [newCourses, setNewCourses] = useState([]);

  const [userCourses, setUserCourses] = useState([]);
  const [userCoursesPagination, setUserCoursesPagination] = useState({});

  const [freeCourses, setFreeCourses] = useState([]);
  const [freeCoursesPagination, setFreeCoursesPagination] = useState({});

  const [video, setVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const [isSingleVideoLoading, setIsSingleVideoLoading] = useState(true);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [isLoadingNewCourses, setIsLoadingNewCourses] = useState(true);
  const [isLoadingUserCourses, setIsLoadingUserCourses] = useState(true);
  const [isLoadingFreeCourses, setIsLoadingFreeCourses] = useState(true);

  const [searchInputText, setSearchInputText] = useState('');
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResultsLength, setSearchResultsLength] = useState(0);

  const navigate = useNavigate();

  const resetState = useCallback(() => {
    setCourses([]);
    setUserCourses([]);
    setFreeCourses([]);

    setSearchInputText('');
    setSearchResultsLength(0);
    setCoursesPagination({});
    setFreeCoursesPagination({});
    setUserCoursesPagination({});

    // setVideos([]);
    // setCourse(null);
  }, [pathname]);

  // loading state for search input. It's logic differs from regular
  // loading state, so it has a separate hook
  useEffect(() => {
    if (!isLoadingFreeCourses || !isLoadingUserCourses || !isLoadingCourses) {
      setSearchInProgress(false);
    }
  }, [isLoadingFreeCourses, isLoadingUserCourses, isLoadingCourses]);

  // Reset loaded entities in case of error
  useEffect(() => {
    setCourse(null);
    setVideo(null);
  }, [error]);

  // Load new courses on user change/init
  useEffect(() => {
    if (user) {
      setUserCourses([]);
      // eslint-disable-next-line no-use-before-define
      loadNewCourses();
    }
  }, [user]);

  /**
   * Request image data for the object and extend it
   * @param {Object} rawObj Object with id property
   * @returns Extended object
   */
  // eslint-disable-next-line no-underscore-dangle
  const _extendWithImage = async (rawObj) => {
    return getAccess()
      .then(async (token) => {
        return coursesAPI.getImageByID(token, rawObj.id).then((respImg) => {
          return { ...rawObj, image: respImg };
        });
      })
      .catch(() => {});
  };

  /**
   * Extend courses with images data
   * Wait for all promises for image data to be executed
   * Then extend courses and return them
   * @param {Array} rawCourses Array of courses objects
   * @returns Extended courses objects
   */
  // eslint-disable-next-line no-underscore-dangle
  const _extendAllWithImages = async (rawCourses) => {
    // Init every image load and collect promises
    const imgPromises = [];
    rawCourses?.forEach((c) => {
      imgPromises.push(_extendWithImage(c));
    });
    // Return combined result when all promises executed
    // promises resolved in the same order!
    return Promise.all(imgPromises).then((values) => {
      const extCourses = [];
      values.forEach((extCourse) => {
        extCourses.push(extCourse);
      });
      return extCourses;
    });
  };

  /**
   * Load all courses
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sort Sort list by field name with + or - at the end for select sort direction
   */
  const loadCourses = async (page, search, size, sort) => {
    setIsLoadingCourses(true);
    getAccess()
      .then((token) => {
        coursesAPI
          .getCourses(token, undefined, undefined, page, search, size, sort)
          .then((resp) => {
            setSearchResultsLength(resp?.pagination.totalCount);
            _extendAllWithImages(resp?.items).then((extCourses) => {
              const mergedCourses = uniqueMerge(courses, extCourses, 'id');
              setCourses(mergedCourses);
              setCoursesPagination(resp ? { ...resp?.pagination } : {});
              setIsLoadingCourses(false);
            });
          });
      })
      .catch(() => {
        setUserNull();
      });
  };

  /**
   * Load new courses
   */
  const loadNewCourses = async () => {
    setIsLoadingNewCourses(true);
    getAccess()
      .then((token) => {
        // eslint-disable-next-line max-len
        coursesAPI
          .getCourses(
            token,
            undefined,
            undefined,
            undefined,
            undefined,
            COURSES_NEW_ELEMENTS_COUNT,
            CoursesSortEnum.createdAtDesc
          )
          .then((resp) => {
            _extendAllWithImages(resp?.items).then((extCourses) => {
              setNewCourses([...extCourses]);
              setIsLoadingNewCourses(false);
            });
          });
      })
      .catch(() => {
        setUserNull();
      });
  };

  /**
   * Load user's courses
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sort Sort list by field name with + or - at the end for select sort direction
   */
  const loadUserCourses = async (page, search, size, sort) => {
    setIsLoadingUserCourses(true);
    getAccess()
      .then((token) => {
        coursesAPI
          .getCourses(token, undefined, true, page, search, size, sort)
          .then((resp) => {
            if (resp) {
              setSearchResultsLength(resp.pagination.totalCount);
              _extendAllWithImages(resp.items).then((extCourses) => {
                const mergedUserCourses = uniqueMerge(
                  userCourses,
                  extCourses,
                  'id'
                );
                setUserCourses(mergedUserCourses);
                setUserCoursesPagination(resp ? { ...resp.pagination } : {});
                setIsLoadingUserCourses(false);
              });
            } else {
              // Clear user courses if there are not courses for user
              setUserCourses([]);
              setUserCoursesPagination({});
              setIsLoadingUserCourses(false);
            }
          });
      })
      .catch(() => {
        setUserNull();
      });
  };

  /**
   * Load free courses
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sort Sort list by field name with + or - at the end for select sort direction
   */
  const loadFreeCourses = async (page, search, size, sort) => {
    setIsLoadingFreeCourses(true);
    return getAccess()
      .then((token) => {
        coursesAPI
          .getCourses(token, true, undefined, page, search, size, sort)
          .then((resp) => {
            if (resp) {
              setSearchResultsLength(resp.pagination.totalCount);
              _extendAllWithImages(resp.items).then((extCourses) => {
                const mergedFreeCourses = uniqueMerge(
                  freeCourses,
                  extCourses,
                  'id'
                );
                setFreeCourses(mergedFreeCourses);
                setFreeCoursesPagination(resp ? { ...resp.pagination } : {});
                setIsLoadingFreeCourses(false);
              });
            } else {
              // Clear user courses if there are not courses for user
              setFreeCourses([]);
              setFreeCoursesPagination({});
              setIsLoadingFreeCourses(false);
            }
          });
      })
      .catch(() => {
        setUserNull();
      });
  };

  /**
   * Loads course and related videos
   * @param {String} id Course ID
   */
  const loadCourseById = async (id) => {
    // Reset loaded course & video. Preventing ghost appearance
    setCourse(null);
    setVideos([]);
    setIsLoadingVideos(true);

    getAccess()
      .then((token) => {
        coursesAPI.getCourseById(token, id).then((resp) => {
          if (resp && resp?.status === 200) {
            // Everything OK
            _extendWithImage(resp.data).then((extCourse) => {
              setCourse(extCourse);
            });
          } else {
            setCourse(undefined);
          }
        });
        coursesAPI.getCourseVideos(token, id).then((resp) => {
          if (resp && resp?.status === 200) {
            if (resp?.data?.items?.length) {
              _extendAllWithImages(resp.data.items).then((extVideos) => {
                setVideos(extVideos);
                setIsLoadingVideos(false);
              });
              return;
            }
          }
          setIsLoadingVideos(false);
        });
      })
      .catch(() => {
        setIsLoadingVideos(false);
        setUserNull();
      });
  };

  const loadMoreVideos = async (page) => {
    const token = await getAccess();
    coursesAPI
      .getCourseVideos(token, course.id, page)
      .then((resp) => {
        if (resp && resp?.status === 200) {
          if (resp?.data?.items?.length) {
            _extendAllWithImages(resp.data.items).then((extVideos) => {
              setVideos((prev) => [...prev, ...extVideos]);
              setIsLoadingVideos(false);
            });
            return;
          }
        }
        setIsLoadingVideos(false);
      })
      .catch(() => {
        setIsLoadingVideos(false);
        setUserNull();
      });
  };

  /**
   * Loads the video by ID and parent course ID
   * @param {String} videoId Video ID
   * @param {String} courseId Course ID
   */
  const loadVideoById = async (videoId, courseId) => {
    setIsSingleVideoLoading(true);
    setVideo(null);
    getAccess()
      .then((token) => {
        coursesAPI.getVideoById(token, videoId, courseId).then((resp) => {
          if (resp && resp?.status === 200) {
            _extendWithImage(resp.data).then((extVideo) => {
              setVideo(extVideo);
              setIsSingleVideoLoading(false);
            });
          } else {
            setVideo(undefined);
          }
        });
      })
      .catch(() => {
        setUserNull();
      });
  };

  const subscribeToCourse = async (courseId) => {
    getAccess()
      .then((token) => {
        coursesAPI.subscribeToCourse(token, courseId).then((resp) => {
          if (resp) {
            if (course.id === courseId) {
              setCourse({ ...course, isPurchased: true });
            }
          }
        });
      })
      .then(() => {
        navigate(`/mycourses/${courseId}/${videos[0]?.id}`);
      })
      .catch(() => {
        setUserNull();
      });
  };

  const loadCurrentPageCourses = (currentPage, searchText) => {
    setCourses([]);
    setFreeCourses([]);
    setUserCourses([]);
    setSearchInputText(searchText.trim());

    if (currentPage === 'mycourses') {
      loadUserCourses(
        1,
        searchText.trim(),
        COURSES_MYCOURSES_ELEMENTS_COUNT,
        CoursesSortEnum.purchasedAtDesc
      );
      return;
    }

    if (currentPage === 'free') {
      loadFreeCourses(
        1,
        searchText.trim(),
        COURSES_HOME_ELEMENTS_COUNT,
        CoursesSortEnum.usersCountDesc
      );
      return;
    }
    loadCourses(
      1,
      searchText.trim(),
      COURSES_HOME_ELEMENTS_COUNT,
      CoursesSortEnum.usersCountDesc
    );
  };

  const onSearchChange = (v) => {
    setSearchInputText(v);
  };

  const onSearchInProgress = (v) => setSearchInProgress(v);

  const value = useMemo(
    () => ({
      course,
      courses,
      coursesPagination,
      newCourses,
      userCourses,
      userCoursesPagination,
      freeCourses,
      freeCoursesPagination,
      video,
      videos,
      isLoadingVideos,
      isLoadingCourses,
      isLoadingNewCourses,
      isLoadingUserCourses,
      isLoadingFreeCourses,
      searchInputText,
      resetState,
      searchInProgress,
      loadCourses,
      loadUserCourses,
      setSearchInputText,
      setSearchResultsLength,
      setCoursesPagination,
      loadFreeCourses,
      loadCourseById,
      loadMoreVideos,
      loadVideoById,
      subscribeToCourse,
      isSingleVideoLoading,
      loadCurrentPageCourses,
      onSearchChange,
      onSearchInProgress,
      searchResultsLength,
    }),
    [
      course,
      courses,
      coursesPagination,
      resetState,
      newCourses,
      userCourses,
      userCoursesPagination,
      freeCourses,
      freeCoursesPagination,
      setSearchInputText,
      setSearchResultsLength,
      setCoursesPagination,
      video,
      searchInputText,
      searchInProgress,
      videos,
      isLoadingVideos,
      isLoadingCourses,
      isLoadingNewCourses,
      isLoadingUserCourses,
      isLoadingFreeCourses,
      loadCurrentPageCourses,
      onSearchChange,
      onSearchInProgress,
      searchResultsLength,
    ]
  );

  return (
    <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>
  );
}

CoursesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
