import React from 'react';
import PropTypes from 'prop-types';

import styles from './TimingList.module.scss';

import { prepareTimeFromSeconds } from '../../utils/strings';
import useVideoControls from '../../hook/useVideoControls';

function TimingList({ timings, onSelect }) {
  const { setTargetSec, setIsPlaying } = useVideoControls();

  const handleSelect = (time) => {
    setTargetSec(time);
    setIsPlaying(true);
    onSelect(time);
  };

  return (
    <div className={styles.wrapper}>
      {/* eslint-disable-next-line arrow-body-style */}
      {timings && timings?.length > 0 ? timings.map((timing, index) => {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div // eslint-disable-line jsx-a11y/no-static-element-interactions
            key={timing.sectionStart}
            className={styles.card}
            onClick={() => handleSelect(timing.sectionStart)}
          >
            <div className={styles.titleHolder}>
              { /* eslint-disable-next-line react/jsx-one-expression-per-line */ }
              <div className={styles.title}>Эпизод {index + 1}</div>
              <div className={styles.time}>{prepareTimeFromSeconds(timing.sectionStart)}</div>
            </div>
            <div className={styles.text}>{timing?.title}</div>
          </div>
        );
      }) : <p>Нет эпизодов</p>}
    </div>
  );
}

TimingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  timings: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
};

TimingList.defaultProps = {
  onSelect: () => null,
};

export default TimingList;
