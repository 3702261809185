import { useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import styles from './VideoListCard.module.scss';
// TODO: video thumb image should be replaced by production version
import coverDummyImg from '../../../assets/images/video_thumb_stub.png';

import { CourseShape } from '../../../apis/models/course';
import { VideoShape } from '../../../apis/models/video';
import { prepareTimeFromSeconds } from '../../../utils/strings';
import { ImageParamsEnum, ImageSizeEnum } from '../../../utils/settings';
import { joinClasses } from '../../../utils/helper';

import { buildRoute as buildVideoRoute } from '../../../containers/VideoPage/VideoPage.Route';
import useCourses from '../../../hook/useCourses';

function VideoListCard({ course, video }) {
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { subscribeToCourse } = useCourses();
  const navigate = useNavigate();

  const getRoundMin = (seconds) => {
    const min = Math.round(seconds / 60);
    return min || 1;
  };

  // Prepare video thumb image
  const coverImg = useMemo(() => {
    const imgObj = video?.image?.instances?.[ImageSizeEnum.thumb];
    return (
      imgObj || {
        url: coverDummyImg,
        width: ImageParamsEnum[ImageSizeEnum.thumb].width,
        height: ImageParamsEnum[ImageSizeEnum.thumb].height,
        alt: 'Изображение загружается',
      }
    );
  }, [video]);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (course.isFree && !isSubscribed && !course.isPurchased) {
      setLoading(true);
      try {
        await subscribeToCourse(course.id);
        setIsSubscribed(true);
        navigate(buildVideoRoute(course, video));
      } catch (error) {
        console.error('Subscription failed', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getBody = () => (
    <>
      <div className={styles.videoCover}>
        <img
          src={coverImg.url}
          width={coverImg.width}
          height={coverImg.height}
          alt={video.title}
        />
      </div>
      <div className={styles.videoText}>
        <h4 className={styles.videoHeading}>{video.title}</h4>
        {/* <p className={styles.videoDescription}>{video.description}</p> */}
      </div>
      <div className={styles.videoDuration}>
        {/* <img src={clock} width="12px" height="12px" alt="Продолжительность видео" /> */}
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <span className={styles.approxDuration}>
          {getRoundMin(video.duration)}
          &thinsp;мин
        </span>
        <span className={styles.exactDuration}>
          {prepareTimeFromSeconds(video.duration)}
        </span>
      </div>
    </>
  );

  if (course.isPurchased || isSubscribed) {
    return (
      <NavLink
        className={({ isActive }) =>
          joinClasses([styles.videoCard, isActive ? styles.activeCard : ''])}
        to={buildVideoRoute(course, video)}
      >
        {getBody()}
      </NavLink>
    );
  }

  if (course.isFree) {
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={joinClasses([styles.videoCard, styles.clickable])}
        onClick={handleSubscribe}
      >
        {loading ? <div>Загрузка...</div> : getBody()}
      </div>
    );
  }

  return (
    <div className={joinClasses([styles.videoCard, styles.disabled])}>
      {getBody()}
    </div>
  );
}

VideoListCard.propTypes = {
  // Course object
  course: CourseShape.isRequired,
  // Video object
  video: VideoShape.isRequired,
};

VideoListCard.defaultProps = {};

export default VideoListCard;
