import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const CourseShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  expirationTime: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  // image: PropTypes.string,
  videosCount: PropTypes.number,
  usersCount: PropTypes.number,
  isFree: PropTypes.bool,
  isPurchased: PropTypes.bool,
  paymentLink: PropTypes.string,
  downloadLink: PropTypes.string,
});
