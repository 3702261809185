/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { getWithExpiry, setWithExpiry } from './storage';

const CHUNK_FAILED_KEY = 'chunk_failed';

export function ErrorFallback({ error }) {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry(CHUNK_FAILED_KEY)) {
        setWithExpiry(CHUNK_FAILED_KEY, 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div>
      <p>Произошла ошибка</p>
    </div>
  );
}
