import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './ErrorHttpPage.module.scss';

import { joinClasses } from '../../utils/helper';
import useError from '../../hook/useError';

import Button from '../../components/Button/Button';
import { getRoute as getHomeRoute } from '../HomePage/HomePage.Route';

// Supported error list with titles and descriptions
const SUPPOROTED_ERRORS = {
  404: {
    title: 'Страница не найдена',
    description: 'К сожалению, такой страницы не существует, но не стоит огорчаться, воспользуйтесь меню или поиском по сайту',
    actionText: 'На главную',
  },
  500: {
    title: 'Внутренняя ошибка сервера',
    description: 'Пожалуйста, подождите некоторое время и попробуйте обновить страницу.\nМы прилагаем все усилия, чтобы скорректировать ситуацию как можно быстрее',
    actionText: 'Обновить страницу',
  },
  default: {
    title: 'Что-то пошло не так',
    description: 'Произошла неизвестная ошибка',
    actionText: 'На главную',
  },
};

/**
 * Page for HTTP errors
 * @component
 */
function ErrorHttpPage({ code, headerFix }) {
  const { resetError } = useError();

  const navigate = useNavigate();

  const error = code in SUPPOROTED_ERRORS ? SUPPOROTED_ERRORS[code] : SUPPOROTED_ERRORS.default;

  const handleAction = useCallback(() => {
    switch (code) {
      case 404:
        resetError();
        navigate(getHomeRoute());
        break;
      case 500:
        window.location.reload();
        break;
      default:
        resetError();
        navigate(getHomeRoute());
        break;
    }
    console.log('handleAction', code);
  }, [code]);

  return (
    <div className={joinClasses([styles.holder, headerFix ? styles.headerFix : ''])}>
      <div className={styles.body}>
        <div className={styles.spacer} />
        <div className={styles.content}>
          <div className={styles.code}>
            {Array.from(code.toString()).map((digit, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index}>{digit}</span>
            ))}
          </div>
          <div className={styles.title}>{error.title}</div>
          <div className={styles.description}>{error.description}</div>
          <Button className={styles.button} onClick={handleAction}>{error.actionText}</Button>
        </div>
        <div className={styles.spacer} />
      </div>
    </div>
  );
}

ErrorHttpPage.propTypes = {
  // HTTP error code
  code: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  // Should this page be rendered in template with header
  headerFix: PropTypes.bool.isRequired,
};

export default ErrorHttpPage;
