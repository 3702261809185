import { memo } from 'react';

import PropTypes from 'prop-types';
import { getColorForCharacter } from '../utils/getColorForCharacter/getColorForCharacter';

import cls from './Avatar.module.scss';
import { joinClasses } from '../../../utils/helper';

export const Avatar = memo(({ data, size = 'md', className }) => {
  const firstLetter = data[0]?.toUpperCase() ?? 'A';
  const background = getColorForCharacter(firstLetter);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background,
      }}
      className={joinClasses([cls.avatar, className, size === 'sm' ? cls.small : size === 'xs' ? cls.extraSmall : ''])}
    >
      {firstLetter}
    </div>
  );
});

Avatar.propTypes = {
  data: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  data: '',
  size: 'md',
  className: '',
};
