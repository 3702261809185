const colors = [
  '#0085c3',
  '#7ab800',
  '#f2af00',
  '#dc5034',
  '#ce1126',
  '#b7295a',
  '#6e2585',
  '#71c6c1',
  '#5482ab',
  '#009bbb',
];

export const getColorForCharacter = (character) => {
  const normalizedCharacter = character.toUpperCase();
  const unicodeValue = normalizedCharacter.charCodeAt(0);
  const colorIndex = unicodeValue % colors.length;
  return colors[colorIndex];
};
