import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './DefaultTemplate.module.scss';

import { SITE_NAME } from '../../utils/settings';
import useError from '../../hook/useError';

import Header from '../../layouts/Header/Header';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import ErrorHttpPage from '../../containers/ErrorHttpPage/ErrorHttpPage';

/**
 * Default page layout
 * @component
 */
function DefaultTemplate({ title, children }) {
  const { error } = useError();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    document.title = `${title} | ${SITE_NAME}`;
  }, [title]);

  return (
    <div className={styles.defaultLayout}>
      <Header togglePopup={(v) => setShowPopup(v)} />
      {/* <Popup showPopup={showPopup} togglePopup={(v) => setShowPopup(v)} /> */}
      <div className={styles.pageWrapper}>
        {(error.code === 0 && (
          <>
            <Breadcrumbs className={styles.breadcrumbs} />
            {children}
          </>
        )) || (
          <ErrorHttpPage code={error.code} headerFix />
        )}
      </div>
    </div>
  );
}

DefaultTemplate.propTypes = {
  // Page title for the browser tab
  title: PropTypes.string.isRequired,
  // Content of the layout
  children: PropTypes.node,
};

DefaultTemplate.defaultProps = {
  children: '',
};

export default DefaultTemplate;
