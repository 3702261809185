/**
 * Check if status code in the range
 * @param {Number} statusCode Response status code
 * @param {Number} minCode Min allowed status code
 * @param {Number} maxCode Max allowed status code
 * @returns false if status not a number or not in range, otherwise true
 */
export const isResponseCodeInRange = (statusCode, minCode, maxCode) => {
  if (typeof statusCode !== 'number') {
    return false;
  }

  if (Number(statusCode) >= Number(minCode)
    && Number(statusCode) <= Number(maxCode)) {
    return true;
  }
  return false;
};

// Check if status code is 2xx
export const isResponse2xx = (statusCode) => isResponseCodeInRange(statusCode, 200, 299);
// Check if status code is 4xx
export const isResponse4xx = (statusCode) => isResponseCodeInRange(statusCode, 400, 499);

/**
 * Return error from response for selected selector
 * @param {Object} response Response object (or null)
 * @param {String|Array} selector Selector for error object
 * @returns String with error or empty string
 */
export const getErrorMessage = (response, selector) => {
  if (!response) {
    return '';
  }
  if (response?.status === 400) {
    const selectors = typeof selector !== 'object' ? [selector] : selector;
    const errors = [];
    selectors.forEach((sel) => {
      const err = response?.errors?.find((error) => error?.location === sel);
      if (err && err?.message) {
        errors.push(err?.message);
      }
    });
    return errors.join('. ');
  }
  return '';
};
