import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext(null);

export const themesEnum = {
  DARK: 'themeDark',
  LIGHT: 'themeLight',
};

const DEFAULT_THEME = themesEnum.LIGHT;

const THEME_KEY = 'theme';

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('');

  useEffect(() => {
    const themeStored = localStorage.getItem(THEME_KEY);
    setTheme(themeStored || DEFAULT_THEME);
  }, []);

  const setThemeInternal = (themeUpd) => {
    // Set theme if it's supported only
    if (Object.values(themesEnum).includes(themeUpd)) {
      localStorage.setItem(THEME_KEY, themeUpd);
      setTheme(themeUpd);
    } else {
      localStorage.setItem(THEME_KEY, DEFAULT_THEME);
      setTheme(DEFAULT_THEME);
    }
  };

  const switchTheme = () => {
    if (theme === themesEnum.DARK) {
      setThemeInternal(themesEnum.LIGHT);
    } else {
      setThemeInternal(themesEnum.DARK);
    }
  };

  const value = useMemo(() => ({
    theme,
    setTheme: setThemeInternal,
    switchTheme,
  }), [theme]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
