/* eslint-disable object-property-newline */
import React, {
  createContext,
  // useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const ErrorContext = createContext(null);

export function ErrorProvider({ children }) {
  const [error, setError] = useState({
    code: 0,
    message: '',
  });

  // useEffect(() => {
  //   console.log('upd: ', error);
  // }, [error]);

  /**
   * Raise new error
   */
  const raiseError = (code, message) => {
    const errUpd = { code, message };
    setError(errUpd);
  };

  /**
   * Reset current error
   */
  const resetError = () => {
    // console.log('resetError', error);
    if (error.code) {
      const errUpd = { code: 0, message: '' };
      setError(errUpd);
    }
  };

  const value = useMemo(() => ({
    error,
    raiseError,
    resetError,
  }), [error]);

  return (
    <ErrorContext.Provider value={value}>
      {children}
    </ErrorContext.Provider>
  );
}

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
