import { useContext } from 'react';
import { ErrorContext } from '../hoc/ErrorProvider';

export default function useError() {
  const context = useContext(ErrorContext);
  if (context === null) {
    throw new Error('useError must be used within a ErrorProvider');
  }
  return context;
}
