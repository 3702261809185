import styles from './CoursesNewColumn.module.scss';

import useCourses from '../../hook/useCourses';
import { COURSES_NEW_ELEMENTS_COUNT } from '../../utils/settings';

import CoursesNewTile from './CoursesNewTile/CoursesNewTile';
import CoursesNewTileSkeleton from './CoursesNewTile/CoursesNewTile.skeleton';
import useWindowSize from '../../hook/useWindowSize';
import { desktopScreenWidth } from '../../utils/consts';

function CoursesNewColumn() {
  const { newCourses, isLoadingNewCourses } = useCourses();
  const { width } = useWindowSize();

  return (
    <div className={width < desktopScreenWidth ? styles.mobileContainer : styles.grid}>
      {(isLoadingNewCourses && (
        <>
          {Array(COURSES_NEW_ELEMENTS_COUNT)
            .fill(0)
            .map((_, index) => (
              <CoursesNewTileSkeleton
                key={index} // eslint-disable-line react/no-array-index-key
                className={styles.item}
              />
            ))}
        </>
      )) || []
        .concat(newCourses)
        .map((course) => (width < desktopScreenWidth
          ? (
            <div
              className={width < desktopScreenWidth ? styles.mobileItem : ''}
            >
              <CoursesNewTile
                key={course.id}
                course={course}
                className={width < desktopScreenWidth ? styles.lightItem : styles.item}
                image={course.image}
              />
            </div>
          )
          : (
            <CoursesNewTile
              key={course.id}
              course={course}
              className={width < desktopScreenWidth ? '' : styles.item}
              image={course.image}
            />
          )
        ))}
    </div>
  );
}

CoursesNewColumn.propTypes = {
};

export default CoursesNewColumn;
