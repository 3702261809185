import { useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import classes from './Search.module.scss';
import searchDarkIcon from '../../assets/icons/themes/dark/search.svg';
import searchIcon from '../../assets/icons/themes/light/search.svg';
import resetIconDark from '../../assets/icons/themes/dark/close-menu.svg';
import resetIcon from '../../assets/icons/themes/light/close-menu.svg';

import useCourses from '../../hook/useCourses';
import useWindowSize from '../../hook/useWindowSize';
import { tabletScreenWidth } from '../../utils/consts';
import useTheme from '../../hook/useTheme';
import { themesEnum } from '../../hoc/ThemeProvider';
import { joinClasses } from '../../utils/helper';

function Search() {
  const inputRef = useRef(null);
  const { theme } = useTheme();
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const {
    onSearchInProgress,
    onSearchChange,
    searchResultsLength,
    loadCurrentPageCourses,
    searchInputText,
    searchInProgress,
  } = useCourses();

  const searchCourses = useCallback(
    debounce((searchText) => {
      if (searchText?.length >= 3 || searchText?.length === 0 || !searchText) {
        loadCurrentPageCourses(
          pathname.slice(1, pathname.length),
          searchText || '',
        );
      }
    }, 500),
    [],
  );

  const onSearch = (e) => {
    e.preventDefault();
    if (width > tabletScreenWidth) {
      window.scrollTo(0, 0);
    }
    onSearchChange(e.target.value);
    searchCourses(e.target.value);
    onSearchInProgress(true);
  };

  const onReset = () => {
    if (searchInputText.length) {
      onSearchInProgress(true);
      onSearchChange('');
      searchCourses();
    }
  };

  return (
    <>
      <div
        className={joinClasses([classes.wrapper])}
      >
        <div className={classes.search}>
          <img src={theme === themesEnum.DARK ? searchDarkIcon : searchIcon} alt="Icon" width={24} height={24} />
          <input
            ref={inputRef}
            placeholder={width <= tabletScreenWidth ? 'Поиск' : 'Поиск по курсам'}
            value={searchInputText}
            onChange={onSearch}
            className={classes.searchInput}
          />
        </div>
        {
          !!searchInputText.length
          && (
          <button type="button" onClick={onReset} className={classes.resetButton}>
            <img
              src={theme === themesEnum.LIGHT ? resetIcon : resetIconDark}
              alt="Icon"
              width={12}
              height={12}
            />
          </button>
          )
        }
      </div>
      {searchInputText.length > 0 && searchInputText.length < 3 && (
      <div className={classes.searchedResults}>
        Продолжайте ввод...
      </div>
      )}
      {!searchInProgress && searchInputText.length >= 3 && (
        <div className={classes.searchedResults}>
          Найдено
          {' '}
          {searchResultsLength}
          {' '}
          результатов по запросу “
          {searchInputText}
          ”
        </div>
      )}
    </>
  );
}

export default Search;
