/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import styles from './TermsPage.module.scss';

import Logo from '../../components/Logo/Logo';

/**
 * Terms page
 * @component
 */
function TermsPage() {
  return (
    <div className={styles.holder}>
      <div className={styles.contentWrapper}>
        <div className={styles.logo}><Logo /></div>

        <h1>Пользовательское соглашение онлайн-сервиса Doctor Star School</h1>

        <p className={styles.leftAligned}>версия документа от «19» декабря 2023 г.</p>

        <h2>Термины</h2>

        <p><span className={styles.emphasized}>Онлайн-сервис Doctor Star School (сервис, система)</span> &mdash; веб-приложение (программное обеспечение/ПО), размещенное разработчиком на веб-странице по адресу <a href="https://school.drstar.ru/" target="_blank" rel="noreferrer">https://school.drstar.ru/</a></p>

        <p><span className={styles.emphasized}>Оператор</span> &mdash; организация, осуществляющая эксплуатацию, обслуживание сервиса.</p>

        <p><span className={styles.emphasized}>Регистрация пользователя</span> &mdash; предоставление пользователем оператору данных о пользователе, необходимых для его дальнейшего опознавания (аутентификации) и предоставления ему доступа к контенту, доступному только после завершения процедуры регистрации и активации аккаунта оператором.</p>

        <p><span className={styles.emphasized}>Пользователь</span> &mdash; физическое или юридическое лицо, прошедшее все этапы процедуры регистрации в сервисе, чья учетная запись была активирована оператором.</p>

        <p><span className={styles.emphasized}>Учетная запись (аккаунт)</span> &mdash; хранимая оператором совокупность данных о пользователе, его личных данных и настройках, доступных пользователю после авторизации в системе.</p>

        <p><span className={styles.emphasized}>Авторизация</span> &mdash; процесс проверки прав доступа пользователя к сервису, отдельным определенным ресурсам или функциям.</p>

        <p><span className={styles.emphasized}>Личный кабинет</span> &mdash; специальный раздел сервиса, доступный только зарегистрированным пользователям, авторизованным в сервисе.</p>

        <p><span className={styles.emphasized}>Контент</span> &mdash; лекции, презентации, семинары, тренинги, мастер-классы, в видео-, аудио-, текстовом форматах, доступные только зарегистрированным пользователям, авторизованным в сервисе.</p>

        <h2>1. Сведения об Операторе</h2>

        <p>1.1. Оператором сервиса Doctor Star School является ООО «КИТ МЕД» ИНН 7701845930, ОГРН 1097746458611, зарегистрированное по адресу: 109544, Москва, Бульвар Энтузиастов, д.2 Бизнес-центр «Голден Гейт», телефон: <a href="tel:+74952259955">+7 (495) 225-99-55</a>, <a href="mailto:info@citmed.ru">info@citmed.ru</a>.</p>

        <h2>2. Общие положения</h2>

        <p>2.1. Настоящий документ является обыкновенной (не публичной) офертой по смыслу ст. 435 ГК РФ.</p>

        <p>2.2. Оферта направляется:</p>

        <p>2.2.1. Ограниченному кругу физических лиц, имеющих диплом о высшем профессиональном образовании с присвоением квалификации врача, соответствующего образца, выданного высшими учебными заведениями;</p>

        <p>2.2.2. Ограниченному кругу юридических лиц и индивидуальных предпринимателей, осуществляющих медицинскую деятельность (в том числе оказание косметологических услуг) на основании лицензии в соответствии с п. 46 ч. 1 ст. 12, Федерального закона от 04.05.2011 N 99-ФЗ «О лицензировании отдельных видов деятельности».</p>

        <p>2.3. В соответствии со ст. 438 ГК РФ полным и безоговорочным акцептом (принятием) настоящей оферты является завершение пользователем процедуры подачи заявки на регистрацию аккаунта, которая включает в себя:</p>

        <p>2.3.1. Ознакомление пользователя с настоящим пользовательским соглашением и подтверждение его согласия с изложенными в нем положениями, путем проставления отметки «✓» в соответствующем поле, что приравнивается к собственноручной подписи пользователя- физического лица или собственноручной подписи уполномоченного лица пользователя- юридического лица;</p>

        <p>2.3.2. Ознакомление пользователя с политикой конфиденциальности и подтверждение его согласия с изложенными в ней положениями, путем проставления отметки «✓» в соответствующем поле, что приравнивается к собственноручной подписи пользователя- физического лица или собственноручной подписи уполномоченного лица пользователя- юридического лица;</p>

        <p>2.3.3. Предъявление оператору необходимых для регистрации документов, подтверждающих соответствие пользователя критериям, указанным в пунктах 2.2.1, 2.2.2, путем загрузки электронных образов соответствующих документов в процессе подачи заявки на регистрацию аккаунта.</p>

        <p>2.4. Частичный акцепт, а равно акцепт оферты на иных условиях не допускается.</p>

        <h2>3. Предмет соглашения</h2>

        <p>3.1. Оператор на условиях настоящего соглашения предоставляет пользователю доступ к контенту, размещенному оператором в самом сервисе, либо размещенному на сторонних ресурсах, но доступному для ознакомления на сервисе.</p>

        <p>3.2. Контент включает в себя лекции, презентации, семинары, тренинги, мастер-классы, в видео-, аудио-, текстовом форматах.</p>

        <p>3.4. Оператор имеет право изменять условия настоящей оферты и дополнений к ней без предварительного согласования с пользователем, обеспечивая при этом публикацию измененных условий на сайте сервиса не менее чем за один день до их ввода в действие.</p>

        <h2>4. Условия и порядок предоставления услуг</h2>

        <p>4.1. Контент, размещенный оператором на сервисе, доступ к которому предоставляется зарегистрированным и авторизованным пользователям носит ознакомительный характер.</p>

        <p>4.2. Ознакомление с контентом не связано с обучением, приобретением специальных навыков, повышением квалификации.</p>

        <p>4.3. Оператор не разрабатывает учебные планы и образовательные программы, методики обучения, формы аттестации и контроля результатов.</p>

        <p>4.4. Пользователь, принимая оферту, дает свое согласие на получение им смс-сообщений (уведомлений, оповещений), направляемых оператором по номеру телефона пользователя для достижения целей, предусмотренных настоящим соглашением (регистрация, активация, уведомления об изменениях и другие).</p>

        <p>4.5. Пользователь, принимая оферту, дает свое согласие на получение им сообщений (уведомлений, оповещений), направляемых оператором по адресу электронной почты пользователя для достижения целей, предусмотренных настоящим соглашением (регистрация, активация, уведомления об изменениях и другие).</p>

        <p>4.6. Доступ к бесплатному контенту осуществляется беспрепятственно, доступ к контенту, за доступ к которому правообладатель предусмотрел плату, осуществляется только после подтверждения правообладателем факта оплаты конкретным пользователем установленной за доступ к контенту цены.</p>

        <p>4.7. Доступ к сервису, к контенту, размещенному на сервисе, предоставляется на условиях «как есть», без каких-либо прямых или косвенных гарантий того, что указанное может подходить или не подходить для конкретных целей пользователя.</p>

        <h2>5. Права и обязанности сторон</h2>

        <p>5.1. Пользователь обязан обеспечить сохранность конфиденциальных данных необходимых для авторизации (логин, пароль, код из смс-сообщения, код из письма, отправленного по адресу электронной почты и др.), не допускать их попадания в распоряжение третьих лиц.</p>

        <p>5.2. Пользователь обязуется не выполнять сохранение, копирование контента, не осуществлять его трансляцию, не предпринимать попыток распространения контента иными способами без согласия оператора и правообладателя.</p>

        <p>5.3. Пользователь обязан сообщать о себе достоверные сведения, не распространять персональные данные третьих лиц без их согласия.</p>

        <p>5.4. Оператор имеет право в любой момент расторгнуть настоящее соглашение в одностороннем внесудебном порядке без предварительного уведомления пользователя.</p>

        <h2>6. Ответственность сторон</h2>

        <p>6.1. Пользователь принимает на себя полную ответственность и риски, связанные с применением им информации, полученной посредством ознакомления с контентом.</p>

        <p>6.2. Пользователь несет полную ответственность, связанную с последствиями использованиями третьими лицами его конфиденциальных данных для доступа (авторизации) в сервисе.</p>

        <p>6.3. Пользователь несет полную ответственность за использование третьими лицами информации, передаваемой оператором по адресу электронной почты пользователя и другим каналам связи с ним.</p>

        <p>6.4. Оператор не несет ответственность за качество доступа к сервису, на которое может повлиять скорость интернет-соединения пользователя, скорость интернет-соединения хостинг- провайдера оператора, стабильность инфраструктуры хостинг-провайдера оператора.</p>

        <h2>7. Интеллектуальная собственность</h2>

        <p>7.1. Наименование «Doctor Star» является зарегистрированным товарным знаком (знаком обслуживания), исключительное право на который действует на всей территории Российской Федерации, а также за пределами РФ, согласно международным договорам РФ.</p>

        <p>7.2. Автором и правообладателем исключительного права на контент может являться, как оператор сервиса, так и другие лица.</p>

        <p>7.3. Запись, копирование и распространение контента или любых его элементов без согласия правообладателя не допускается.</p>

        <h2>8. Разрешение споров</h2>

        <p>8.1. Все споры и разногласия решаются путем переговоров сторон.</p>

        <p>8.2. В случае если споры и разногласия не могут быть урегулированы путем переговоров, они передаются на рассмотрение суда по месту нахождения оператора.</p>

        <h2>9. Вступление соглашения в силу, прекращение действия</h2>

        <p>9.1. Настоящее соглашение вступает в силу с момента акцепта пользователем условий настоящей оферты и действует до тех пор, пока оператор не примет решение отказаться от исполнения соглашения в одностороннем внесудебном порядке согласно пункту 6.4. настоящего соглашения.</p>
      </div>
    </div>
  );
}

TermsPage.propTypes = {
};

export default TermsPage;
