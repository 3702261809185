import { useParams } from 'react-router-dom';

import styles from './ProfilePage.module.scss';

import { joinClasses } from '../../utils/helper';

import ProfileCard from '../../components/ProfileCard/ProfileCard';
import ChangePersonal from '../../components/Forms/ChangePersonal/ChangePersonal';
import { ProfileActionEnum } from './ProfilePage.Route';
import useAuth from '../../hook/useAuth';

const TitlesEnum = {
  [ProfileActionEnum.changePassword]: 'Изменение пароля',
  [ProfileActionEnum.changePersonal]: 'Настройка личных данных',
};

/**
 * Profile page
 * @component
 */
function ProfilePage() {
  const params = useParams();
  const { user } = useAuth();
  console.log(user);
  const renderHeading = () => {
    if (!params.profileAction
      || !Object.values(ProfileActionEnum)
        .includes(params.profileAction)) {
      return <h1 className={styles.title}>{user?.name}</h1>;
    }
    return <h1 className={styles.title}>{TitlesEnum[params.profileAction]}</h1>;
  };

  // TODO: return 404 error page for usnupported actions
  return (
    <div className={styles.holder}>
      {renderHeading()}

      {(!params.profileAction && (
        <div className={styles.block}>
          <ProfileCard />
        </div>
      ))}
      {(params.profileAction === ProfileActionEnum.changePersonal && (
        <div className={joinClasses([styles.block, styles.form])}>
          <ChangePersonal isFocused />
        </div>
      ))}
    </div>
  );
}

ProfilePage.propTypes = {};

export default ProfilePage;
