import React from 'react';
import PropTypes from 'prop-types';

import './InputWrapper.scss';
import styles from './InputWrapper.module.scss';

import { joinClasses } from '../../utils/helper';

// Nested input style
export const InputStyles = {
  DEFAULT: styles.inputDefault,
  NOBORDER: styles.inputNoborder,
  LINED: styles.inputLined,
};

// export const phoneInputClass = styles.phoneInput;
export const phoneInputInnerClass = 'inputphone-custom';

/**
 * Input wrapper with label and error text
 * @component
 */
function InputWrapper({
  className,
  label,
  labelFor,
  errorText,
  children,
}) {
  const cls = joinClasses([styles.inputContainer, className]);

  return (
    <div className={styles.inputWrapper}>
      <label
        className={styles.labelText}
        htmlFor={labelFor}
      >
        {label}
      </label>
      <div className={cls}>
        {children}
      </div>
      {errorText && <p className={styles.errorText}>{errorText}</p>}
    </div>
  );
}

InputWrapper.propTypes = {
  // The text will be shown on error
  errorText: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelFor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

InputWrapper.defaultProps = {
  className: '',
  errorText: '',
};

export default InputWrapper;
