import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './MenuItem.module.scss';

import { joinClasses } from '../../../utils/helper';

/**
 * Menu link represented by NavLink react component
 * Standard NavLink behavior is used for the item's activity state unless explicitly set
 * @component
 */
function MenuItem({
  title,
  to,
  onClick,
  className,
  isActive,
}) {
  const getClass = (isActiveLink) => {
    const cls = joinClasses([styles.menuitem, className]);
    // If isActive state is explicitly set
    if (typeof isActive !== 'undefined') {
      return isActive ? joinClasses([cls, styles.active]) : cls;
    }
    return isActiveLink ? joinClasses([cls, styles.active]) : cls;
  };

  /* eslint-disable no-shadow */
  return (
    <NavLink
      className={({ isActive }) => getClass(isActive)}
      to={to}
      onClick={onClick}
    >
      <span>
        {title}
      </span>
    </NavLink>
  );
}

MenuItem.propTypes = {
  // Title of menu item
  title: PropTypes.string.isRequired,
  // Menu item link
  to: PropTypes.string.isRequired,
  // onClick handler function
  onClick: PropTypes.func,
  // Custom class
  className: PropTypes.string,
  // Externally set active state (override the NavLink isActive)
  isActive: PropTypes.bool,
};

MenuItem.defaultProps = {
  // isActive: false,
  onClick: () => null,
  className: '',
  isActive: undefined,
};

export default MenuItem;
