const ACCESS_TOKEN_KEY = 'at';
const REFRESH_TOKEN_KEY = 'refreshToken';

/**
 * A simple service for encapsulating token management
 */
const TokenService = {
  getAccessToken: async () => localStorage.getItem(ACCESS_TOKEN_KEY),

  getRefreshToken: async () => localStorage.getItem(REFRESH_TOKEN_KEY),

  setAccessToken: async (token) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  },

  setRefreshToken: async (token) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  },

  removeAccessToken: async () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  },

  removeRefreshToken: async () => {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

export default TokenService;
