import React from 'react';

import styles from './QuestionsPage.module.scss';

/**
 * Questions page
 * @component
 */
function QuestionsPage() {
  return (
    <div className={styles.holder}>
      <div>Questions Page</div>
    </div>
  );
}

QuestionsPage.propTypes = {
};

export default QuestionsPage;
