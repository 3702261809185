import axios from 'axios';
import { getAxiosRequestConfig } from '@citmed/auth';
// eslint-disable-next-line import/no-cycle
import { getAuthConfig } from './getAuthConfig';

export const api = axios.create({
  // withCredentials: true,
  baseURL: ENV.API_URL,
});

// Registering the custom error handler to the "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response?.status;

  // Logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
});

api.interceptors.request.use((config) =>
  getAxiosRequestConfig(config, getAuthConfig())
);

export default api;
