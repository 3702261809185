import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './CoursesGridTile.module.scss';

import stubImgLight from '../../../assets/images/themes/light/course_stub.png';
import stubImgDark from '../../../assets/images/themes/dark/course_stub.png';

import { ImageParamsEnum, ImageSizeEnum } from '../../../utils/settings';
import useTheme from '../../../hook/useTheme';

import { CourseShape } from '../../../apis/models/course';
import { ImageShape } from '../../../apis/models/image';
import { buildRoute as buildCourseRoute } from '../../../containers/CoursePage/CoursePage.Route';
import { themesEnum } from '../../../hoc/ThemeProvider';
import useCourses from '../../../hook/useCourses';
import TextHighlighter from '../../../components/TextHighlighter/TextHighlighter';

function CoursesGridTile({ course, image }) {
  const { theme } = useTheme();
  const { searchInputText } = useCourses();
  const imgParams = ImageParamsEnum[ImageSizeEnum.thumb];
  // Stub image in case of missing image
  const tileImg = useMemo(() => {
    const imgLink = image?.instances?.[ImageSizeEnum.thumb]?.url;
    if (imgLink) {
      return imgLink;
    }
    switch (theme) {
      case themesEnum.DARK:
        return stubImgDark;
      case themesEnum.LIGHT:
      default:
        return stubImgLight;
    }
  }, [image, theme]);

  return (
    <Link to={buildCourseRoute(course)} className={styles.tile}>
      <div className={styles.image}>
        <img
          src={tileImg}
          alt={course.title}
          width={imgParams.width}
          height={imgParams.height}
        />
      </div>
      {
        searchInputText.length >= 3
          ? (
            <div className={styles.info}>
              <TextHighlighter
                classes={[styles.title]}
                search={searchInputText}
                text={course.title}
              />
              <TextHighlighter
                classes={[styles.description]}
                search={searchInputText}
                text={course.shortDescription}
              />
            </div>
          )
          : (
            <div className={styles.info}>
              <div className={styles.title}>{course.title}</div>
              <div className={styles.description}>{course.shortDescription}</div>
            </div>
          )
      }
    </Link>
  );
}

CoursesGridTile.propTypes = {
  // Course data structure
  course: CourseShape.isRequired,
  // Course image
  image: ImageShape,
};

CoursesGridTile.defaultProps = {
  image: null,
};

export default CoursesGridTile;
