import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './VideoList.module.scss';

import useCourses from '../../hook/useCourses';
import VideoListCard from './VideoListCard/VideoListCard';
import VideoListCardSkeleton from './VideoListCard/VideoListCard.skeleton';
import { joinClasses } from '../../utils/helper';
import { calculateNextPage } from './helpers';

function VideoList({ className }) {
  const {
    course,
    videos,
    isLoadingVideos,
    loadMoreVideos,
  } = useCourses();

  const getBody = () => {
    if (isLoadingVideos || !course) {
      return (
        <>
          {Array(course?.videosCount || 1)
            .fill(0)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, index) => (
              <VideoListCardSkeleton key={index} />
            ))}
        </>
      );
    }

    if (videos.length === 0) {
      return <div className={styles.noVideo}>Нет видео</div>;
    }

    return (
      <InfiniteScroll
        scrollThreshold="0.7"
        dataLength={videos.length}
        next={() => {
          loadMoreVideos(calculateNextPage(videos.length));
        }}
        hasMore={course.videosCount > videos.length}
        loader={<div className={styles.loader} />}
      >
        {videos?.map((video) => (
          <VideoListCard key={video.id} course={course} video={video} />
        ))}
      </InfiniteScroll>
    );
  };

  // TODO: skeleton doesn't work in this case (videos loaded before course)
  return (
    <div className={joinClasses([styles.videoList, className])}>
      {getBody()}
    </div>
  );
}

VideoList.propTypes = {
  className: PropTypes.string,
};

VideoList.defaultProps = {
  className: '',
};

export default VideoList;
