import PropTypes from 'prop-types';
import { ManagerShape } from './manager';
import { SpeakerShape } from './speaker';
import { PictureShape } from './picture';

export const StreamShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  startAt: PropTypes.string,
  iframe: PropTypes.string.isRequired,
  cover: PictureShape,
  managers: PropTypes.arrayOf(ManagerShape),
  speakers: PropTypes.arrayOf(SpeakerShape),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});
