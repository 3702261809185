import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { streamsAPI } from '../apis/streamsAPI';
import useAuth from '../hook/useAuth';

export const StreamsContext = createContext(null);

export function StreamsProvider({ children }) {
  const {
    getAccess,
    setUserNull
  } = useAuth();
  const [isStreamsLoading, setIsStreamsLoading] = useState(false);
  const [isStreamsError, setIsStreamsError] = useState(false);
  const [streamsList, setStreamsList] = useState(null);
  const [pagination, setPagination] = useState({
    size: 5,
    page: 1,
    sorts: 'createdAt-'
  });

  const [stream, setStream] = useState(null);
  const [isStreamLoading, setIsStreamLoading] = useState(false);
  const [isStreamError, setIsStreamError] = useState(false);

  const loadStreams = async (p) => {
    setIsStreamsError(false);
    setIsStreamsLoading(true);
    try {
      const token = await getAccess();
      try {
        const streams = await streamsAPI.fetchStreams(token, p);
        setStreamsList(streams);
      } catch (e) {
        console.error(e);
        setIsStreamsError(true);
        setStreamsList(null);
      } finally {
        setIsStreamsLoading(false);
      }
    } catch (e) {
      setUserNull();
    }
  };

  const loadStream = async (id) => {
    setIsStreamError(false);
    setIsStreamLoading(true);
    setStream(null);
    try {
      const token = await getAccess();
      try {
        const s = await streamsAPI.fetchStream(token, id);
        setStream(s);
      } catch (e) {
        console.error(e);
        setIsStreamError(true);
        setStream(null);
      } finally {
        setIsStreamLoading(false);
      }
    } catch (e) {
      setUserNull();
    }
  };

  const value = useMemo(() => ({
    loadStream,
    loadStreams,
    setPagination,
    isStreamsLoading,
    isStreamsError,
    isStreamLoading,
    isStreamError,
    streamsList,
    stream,
    pagination
  }), [loadStream, loadStreams, setPagination, isStreamsLoading, isStreamsError, streamsList, stream, pagination]);

  return <StreamsContext.Provider value={value}>{children}</StreamsContext.Provider>;
}

StreamsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
