import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './CoursesGrid.module.scss';

import { CourseShape } from '../../apis/models/course';
import CoursesGridTile from './CoursesGridTile/CoursesGridTile';
import CoursesGridTileSkeleton from './CoursesGridTile/CourseGridTile.skeleton';
import { COURSES_HOME_ELEMENTS_COUNT } from '../../utils/settings';

const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
};

function CoursesGrid({ courses, isLoading, onLazyLoad }) {
  const observeTriggerRef = useRef(null);
  const [isObserved, setIsObserved] = useState(false);

  function observeHandler(entries) {
    setIsObserved(entries?.[0]?.isIntersecting);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(observeHandler, OBSERVER_OPTIONS);
    if (observeTriggerRef?.current) {
      observer.observe(observeTriggerRef.current);
    }
    return () => {
      if (observeTriggerRef?.current) {
        observer.unobserve(observeTriggerRef.current);
      }
    };
  }, [observeTriggerRef, isLoading]);

  useEffect(() => {
    // console.log('isObserved trigged!');
    if (isObserved) {
      onLazyLoad();
    }
  }, [isObserved]);

  return (
    <div className={styles.grid}>
      {courses.length > 0 && courses
        .map((course) => (
          <CoursesGridTile
            key={course.id}
            course={course}
            image={course.image}
          />
        ))}
      {isLoading && (
        <>
          {Array(COURSES_HOME_ELEMENTS_COUNT).fill(0).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <CoursesGridTileSkeleton key={index} />
          ))}
        </>
      )}
      {!isLoading && (
        <div ref={observeTriggerRef} className={styles.observeTrigger} />
      )}
    </div>
  );
}

CoursesGrid.propTypes = {
  courses: PropTypes.arrayOf(CourseShape).isRequired,
  isLoading: PropTypes.bool,
  onLazyLoad: PropTypes.func,
};

CoursesGrid.defaultProps = {
  isLoading: false,
  onLazyLoad: () => null,
};

export default CoursesGrid;
