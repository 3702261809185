/**
 * Binds cancellation handler with selected object
 * @param {Object} apiObject
 */
export function defineCancelAPIObject(apiObject) {
  // an object that will contain a cancellation handler
  // associated to each API property name in the apiObject API object
  const cancelApiObject = {};

  // each property in the apiObject API layer object
  // is associated with a function that defines an API call

  // this loop iterates over each API property name
  Object.getOwnPropertyNames(apiObject).forEach((apiPropertyName) => {
    const cancellationControllerObject = {
      controller: undefined,
    };

    // associating the request cancellation handler with the API property name
    cancelApiObject[apiPropertyName] = {
      handleRequestCancellation: () => {
        // if the controller already exists,
        // canceling the request
        if (cancellationControllerObject.controller) {
          // canceling the request and returning this custom message
          cancellationControllerObject.controller.abort();
        }

        // generating a new controller
        // with the AbortController factory
        cancellationControllerObject.controller = new AbortController();

        return cancellationControllerObject.controller;
      },
    };
  });

  return cancelApiObject;
}

/**
 * POJO for Courses API
 */
export class APIError {
  constructor(error) {
    this.status = 0;
    if (error?.response?.data?.status) {
      this.status = error.response.data.status;
    } else {
      this.status = error?.response?.status ? error.response.status : 0;
    }
    this.title = error?.response?.data?.title ? error.response.data.title : 'Unspecified error';
    this.detail = error?.response?.data?.detail ? error.response.data.detail : '';
    this.errors = error?.response?.data?.errors ? error.response.data.errors : [];
  }
}

/**
 * Proint API Error to console
 * @param {String} url Url
 * @param {Object} apiError Response oject with error details
 */
export const showAPIError = (url, apiError) => {
  const detail = apiError?.detail ? `: ${apiError.detail}` : '.';
  console.error(
    'Error (%f) in API call %s. %s%s',
    apiError.status,
    url,
    apiError.title,
    detail,
    apiError.errors,
  );
  if (apiError?.errors?.length) {
    console.table(apiError?.errors);
  }
};
