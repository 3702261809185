import { joinClasses } from '../../../utils/helper';
import styles from '../StreamPage.module.scss';

import useWindowSize from '../../../hook/useWindowSize';
import { desktopScreenWidth } from '../../../utils/consts';
import { PersonComponent } from './PersonComponent';
import { StreamShape } from '../../../apis/models/stream';
import { formatPhone } from '../../../utils/formatPhone';

export function TrainersSection({
  stream,
}) {
  const { width } = useWindowSize();

  const renderSupportCards = () => stream.managers.map((manager) => <PersonComponent
    key={manager.id}
    avatar={manager.cover?.instances[0]?.url}
    description={formatPhone(manager.phone)}
    additionalInfo={manager.email}
    name={`${manager.lastName} ${manager.firstName} ${manager.middleName}`}
    hasLinks
  />);

  const renderTrainersCards = () => stream.speakers.map((speaker) => <PersonComponent
    key={speaker.id}
    avatar={speaker.cover?.instances[0]?.url}
    description={speaker.description}
    name={`${speaker.lastName} ${speaker.firstName} ${speaker.middleName}`}
  />);

  return (
    <div
      className={joinClasses([styles.panel, styles.panelLeft])}
    >
      {stream?.speakers && stream?.speakers?.length > 0 && (
        <div className={styles.trainersHolder}>
          <span className={styles.title}>
            Тренеры технологии
          </span>
          <div className={styles.wrapper}>
            {renderTrainersCards()}
          </div>
        </div>
      )}
      {stream?.managers && stream?.managers?.length > 0 && (
        <div className={styles.trainersHolder}>
          <span className={styles.title}>
            Всегда на связи
          </span>
          {width <= desktopScreenWidth
            ? <div className={styles.supportSection}>
              {renderSupportCards()}
            </div>
            : renderSupportCards()}
        </div>
      )}
    </div>
  );
}

TrainersSection.propTypes = {
  stream: StreamShape.isRequired,
};

TrainersSection.defaultProps = {};
