import { useContext } from 'react';
import { CoursesContext } from '../hoc/CoursesProvider';

export default function useCourses() {
  const context = useContext(CoursesContext);
  if (context === null) {
    throw new Error('useCourses must be used within a CoursesProvider');
  }
  return context;
}
