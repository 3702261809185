import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import styles from '../../layouts/CoursesPageContent/CoursesPageContent.module.scss';
import useWindowSize from '../../hook/useWindowSize';
import { joinClasses } from '../../utils/helper';
import liveIcon from '../../assets/icons/live.svg';
import { StreamShape } from '../../apis/models/stream';
import { formatStreamStartDate } from './utils/formatStreamStartDate';

const livePlug = (
  <span className={styles.dateWrapper}>
    <img src={liveIcon} alt="Icon" />
    {' '}
    В Эфире
  </span>
);

export function Banner({ stream }) {
  const {
    title, speakers, shortDescription,
  } = stream;
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  const renderTitle = () => {
    if (width <= 1050 && width >= 1024) {
      return `${title.slice(0, 50)}...`;
    }
    return title;
  };

  const preview = (
    <button
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      type="button"
      onClick={() => {
        navigate(`/stream/${stream.id}`);
      }}
      className={joinClasses([styles.bannerImage])}
    >
      <img src={stream.cover?.instances[0].url} width="100%" height="100%" />
    </button>
  );

  const isLive = (startAt) => (new Date(startAt).getTime()) < (new Date().getTime());

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      {width <= 720 && preview}
      <div
        onClick={() => {
          navigate(`/stream/${stream.id}`);
        }}
        className={styles.banner}
      >
        {width > 720 && preview}
        <div className={styles.bannerTextWrapper}>
          <span className={styles.bannerTitle}>
            {renderTitle()}
          </span>
          <div className={styles.bannerTexts}>
            <span className={styles.mobilePlug} style={{ fontWeight: 'bold' }}>
              {isLive(stream.startAt) ? livePlug : formatStreamStartDate(stream.startAt)}
            </span>
            <span>
              {shortDescription}
            </span>
            <span className={joinClasses([styles.text, styles.speakersWrapper])}>
              <span className={styles.darkText}>
                Спикеры:
                {' '}
              </span>
              {speakers.map((s) => `${s.lastName} ${s.firstName} ${s.middleName}`).join(', ')}
            </span>

            {/* <span className={styles.text}> */}
            {/*   <span className={styles.darkText}> */}
            {/*     Организатор: */}
            {/*     {' '} */}
            {/*   </span> */}
            {/*   {organizer} */}
            {/* </span> */}
          </div>
        </div>
      </div>
  </div>);
}

Banner.propTypes = {
  stream: StreamShape.isRequired,
};

Banner.defaultProps = {};
