import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './ChangePersonal.module.scss';
import buttonStyles from '../../Button/Button.module.scss';

import useAuth from '../../../hook/useAuth';

import { buildRoute as buildProfileRoute } from '../../../containers/ProfilePage/ProfilePage.Route';
import Button from '../../Button/Button';
import InputWrapper, { InputStyles } from '../../InputWrapper/InputWrapper';
import { joinClasses, removeDoubleSpaces } from '../../../utils/helper';

/**
 * Change personal data
 * @component
 */
function ChangePersonal({ isFocused }) {
  const { user, updateUser } = useAuth();
  const [name, setName] = useState('');
  const [showNameError, setShowNameError] = useState('');
  const [lastname, setLastname] = useState('');
  const [showLastNameError, setShowLastNameError] = useState('');

  const nameRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    nameRef?.current?.focus();
  }, [isFocused]);

  useEffect(() => {
    if (user) {
      setLastname(user.family_name);
      setName(user.given_name);
    }
  }, [user]);

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeLastname = (event) => {
    setLastname(event.target.value);
  };

  const handleSave = (event) => {
    if (event?.type === 'keydown' && event?.key !== 'Enter') {
      return;
    }
    if (name.trim().length === 0 || lastname.trim().length === 0) {
      setShowNameError('Поле обязательно к заполнению');
      return;
    }

    updateUser(name, lastname).then((resp) => {
      if (resp) {
        //! TODO: localStorage should be managed in services only (move to location.service)
        const redirectUrl = localStorage.getItem('changePersonalRedirectUrl');
        if (redirectUrl) {
          localStorage.removeItem('changePersonalRedirectUrl');
          navigate(redirectUrl);
        } else {
          navigate(buildProfileRoute());
        }
      }
    });
  };

  const handleCancel = (event) => {
    if (event?.type === 'keydown' && event?.key !== 'Enter') {
      return;
    }
    localStorage.removeItem('changePersonalRedirectUrl');
    navigate(buildProfileRoute());
  };

  return (
    <div className={styles.componentContainer}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <InputWrapper
          errorText={showNameError}
          className={InputStyles.LINED}
          label="Имя"
          labelFor="personal-name"
        >
          <input
            onInvalid={(e) => {
              e.preventDefault();
              setShowNameError('Поле обязательно к заполнению');
            }}
            onBlur={() => setName((prev) => removeDoubleSpaces(prev))}
            required
            id="personal-name"
            value={name}
            title="Имя"
            onChange={(e) => {
              setShowNameError('');
              handleChangeName(e);
            }}
            onKeyDown={handleSave}
            ref={nameRef}
          />
        </InputWrapper>

        <InputWrapper
          errorText={showLastNameError}
          className={InputStyles.LINED}
          label="Фамилия"
          labelFor="personal-lastname"
        >
          <input
            onInvalid={(e) => {
              e.preventDefault();
              setShowLastNameError('Поле обязательно к заполнению');
            }}
            onBlur={() => {
              setLastname((prev) => removeDoubleSpaces(prev));
            }}
            required
            id="personal-lastname"
            value={lastname}
            title="Фамилия"
            onChange={(e) => {
              setShowLastNameError('');
              handleChangeLastname(e);
            }}
            onKeyDown={handleSave}
          />
        </InputWrapper>

        <div className={styles.buttonsHolder}>
          <button
            className={joinClasses([
              buttonStyles.button,
              buttonStyles.default,
              styles.button,
            ])}
            type="submit"
          >
            Сохранить
          </button>
          <Button className={styles.button} onClick={handleCancel}>
            <span>Отменить</span>
          </Button>
        </div>
      </form>
    </div>
  );
}

ChangePersonal.propTypes = {
  // Flag if first input should be focused on init
  isFocused: PropTypes.bool,
};

ChangePersonal.defaultProps = {
  isFocused: false,
};

export default ChangePersonal;
