import React from 'react';
import PropTypes from 'prop-types';

import styles from './Menu.module.scss';

import { joinClasses } from '../../utils/helper';

function Menu({ children, className }) {
  return (
    <div className={joinClasses([styles.menu, className])}>
      {children}
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
