import { AuthService } from '@citmed/auth';
// eslint-disable-next-line import/no-cycle
import { api } from './axiosConfig';

// eslint-disable-next-line import/no-cycle
import { baseName } from '../../index';

export const getBaseNameFixed = () =>
  baseName?.toString().replace(/^\/*|\/*$/g, '');

export const getAuthConfig = () => ({
  baseNameFixed: baseName,
  callbackUri: `${window.origin}${
    getBaseNameFixed() ? `/${getBaseNameFixed()}` : ''
  }/callback`,
  api,
  apiUrl: ENV?.AUTH_URL,
  clientId: ENV?.CLIENT_ID,
  callbackPagePath: 'callback',
  authService: AuthService,
  redirectURL: `${window.origin}${
    getBaseNameFixed() ? `/${getBaseNameFixed()}` : ''
  }/callback`,
  redirectPath: baseName,
});
