/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import styles from './Header.module.scss';
import defaultImgLight from '../../assets/images/themes/light/user_photo.svg';
import defaultImgDark from '../../assets/images/themes/dark/user_photo.svg';
import burgerLight from '../../assets/icons/themes/light/burger-menu.svg';
import burgerDark from '../../assets/icons/themes/dark/burger-menu.svg';
import closeLight from '../../assets/icons/themes/light/close-menu.svg';
import closeDark from '../../assets/icons/themes/dark/close-menu.svg';

import useAuth from '../../hook/useAuth';
import useTheme from '../../hook/useTheme';
import useError from '../../hook/useError';

import { themesEnum } from '../../hoc/ThemeProvider';

import Logo, { LogoSizeEnum } from '../../components/Logo/Logo';
import Menu from '../../components/Menu/Menu';
import MenuItem from '../../components/Menu/MenuItem/MenuItem';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';

import { buildRoute as getProfileRoute } from '../../containers/ProfilePage/ProfilePage.Route';
import { getRoute as getHomeRoute } from '../../containers/HomePage/HomePage.Route';
import { getRoute as getMyCoursesRoute } from '../../containers/MyCoursesPage/MyCoursesPage.Route';
import { getRoute as getFreeCoursesRoute } from '../../containers/FreePage/FreePage.Route';
import { joinClasses } from '../../utils/helper';

import useCourses from '../../hook/useCourses';
import { Avatar } from '../../components/Avatar';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { resetState } = useCourses();
  const { pathname } = useLocation();

  const { user, logout } = useAuth();
  const { theme, switchTheme } = useTheme();
  const { resetError } = useError();

  const switchMenu = () => setIsOpen(!isOpen);

  const switchProfileDropdown = (event) => {
    setIsProfileOpen(!isProfileOpen);
    event.stopPropagation();
  };

  const closeProfileDropdown = () => {
    setIsProfileOpen(false);
  };

  const finalizeLink = (linkPath) => {
    setIsOpen(false);
    resetError();

    if (linkPath !== pathname) {
      resetState();
    }
  };

  const finalizeMenuLink = () => {
    setIsProfileOpen(false);
    resetError();
  };

  const handleDropdownKey = (event) => {
    if (event.type === 'keydown') {
      switch (event.key) {
        case 'Enter':
          setIsProfileOpen(!isProfileOpen);
          break;
        case 'Escape':
          setIsProfileOpen(false);
          break;
        default:
      }
    }
  };

  const handleLogout = () => {
    logout();
    resetError();
  };

  const userImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return defaultImgDark;
      case themesEnum.LIGHT:
        return defaultImgLight;
      default:
        return null;
    }
  }, [theme]);

  const burgerImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return burgerDark;
      case themesEnum.LIGHT:
        return burgerLight;
      default:
        return null;
    }
  }, [theme]);

  const closeImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return closeDark;
      case themesEnum.LIGHT:
        return closeLight;
      default:
        return null;
    }
  }, [theme]);

  const userName = useMemo(() => {
    if (!user) {
      return '';
    }
    return `${user?.name} ${
      user?.lastName?.length ? ` ${user?.lastName[0]}.` : ''
    }`;
  }, [user]);

  return (
    <div
      className={joinClasses([
        styles.header,
        isOpen ? styles['header-open'] : '',
      ])}
    >
      <div
        className={joinClasses([
          styles.headerContainer,
          isOpen ? styles['headerContainer-open'] : '',
        ])}
      >
        <Link
          to={getHomeRoute()}
          className={styles.logoWrapper}
          onClick={() => finalizeLink(getHomeRoute())}
        >
          <Logo className={styles.logo} size={LogoSizeEnum.SMALL} />
        </Link>

        <div className={styles.spacer} />

        <div className={joinClasses([styles.menu, styles.desktopOnly])}>
          <div className={styles.menuHolder}>
            {user && isOpen && (
              <div
                className={joinClasses([
                  styles.profileBlock,
                  styles.mobileTabletOnly,
                ])}
              >
                <Link
                  className={styles.mobileAvatar}
                  to={getProfileRoute()}
                  onClick={() => finalizeLink(getProfileRoute())}
                >
                  <Avatar size="md" data={user?.given_name || ''} />
                  <div className={styles.name}>
                    {`${user.given_name} ${user?.family_name}`}
                  </div>
                </Link>
              </div>
            )}

            <div className={styles.menuBlock}>
              <Menu className={styles.menuBlockHolder}>
                <MenuItem
                  title="Главная"
                  className={styles.menuItem}
                  to={getHomeRoute()}
                  onClick={() => finalizeLink(getHomeRoute())}
                />
                <MenuItem
                  title="Мои курсы"
                  className={styles.menuItem}
                  to={getMyCoursesRoute()}
                  onClick={() => finalizeLink(getMyCoursesRoute())}
                />
                <MenuItem
                  title="Бесплатные"
                  className={styles.menuItem}
                  to={getFreeCoursesRoute()}
                  onClick={() => finalizeLink(getFreeCoursesRoute())}
                />
              </Menu>

              <div className={styles.spacer} />

              <div className={styles.additionalMenuBlock}>
                <MenuItem
                  title="Переключить тему"
                  className={joinClasses([
                    styles.menuItem,
                    styles.mobileTabletOnly,
                  ])}
                  to="#"
                  isActive={false}
                  onClick={switchTheme}
                />

                {user && (
                  <MenuItem
                    title="Выйти из профиля"
                    className={joinClasses([
                      styles.menuItem,
                      styles.mobileTabletOnly,
                      styles.redselected,
                    ])}
                    onClick={handleLogout}
                  />
                )}
              </div>
            </div>

            <div
              className={styles.profileIcon}
              onKeyDown={handleDropdownKey}
              role="button"
              aria-label="Меню пользователя"
              tabIndex={0}
            />

            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={joinClasses([styles.profile, styles.desktopOnly])}
              onClick={switchProfileDropdown}
            >
              <Avatar size="sm" data={user?.given_name || ''} />

              {isProfileOpen && (
                <Dropdown
                  className={styles.dropdown}
                  title={userName}
                  onRequestClose={closeProfileDropdown}
                >
                  {user && (
                    <>
                      <div className={styles.dropdownItem}>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <span className={styles.link} onClick={switchTheme}>
                          Переключить тему
                        </span>
                      </div>
                      <div className={styles.dropdownItem}>
                        <NavLink
                          to={getProfileRoute()}
                          onClick={finalizeMenuLink}
                        >
                          Настройки
                        </NavLink>
                      </div>
                      <div className={styles.dropdownButton}>
                        <Button onClick={handleLogout}>
                          <span>Выйти</span>
                        </Button>
                      </div>
                    </>
                  )}
                </Dropdown>
              )}
            </div>
          </div>
        </div>

        <div
          className={joinClasses([styles.spacer, styles.mobileTabletOnly])}
        />
        <div
          className={joinClasses([styles.hamburger, styles.mobileTabletOnly])}
          onClick={switchMenu}
          onKeyDown={null}
          role="button"
          tabIndex={0}
          aria-label="Меню"
        >
          {(isOpen && (
            <img src={closeImg} alt="Открыть меню" draggable="false" />
          )) || <img src={burgerImg} alt="Закрыть меню" draggable="false" />}
        </div>
      </div>
    </div>
  );
}

export default Header;
