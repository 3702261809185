/**
 * Prepare time in format h:mm:ss or mm:ss by provided seconds
 * @param {Number} seconds Seconds count
 * @returns String with time or empty string
 */
export const prepareTimeFromSeconds = (seconds) => {
  if (typeof seconds !== 'number') {
    return '';
  }

  const pad = (num) => num.toString().padStart(2, '0');
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (hours > 0) {
    return `${hours}:${pad(minutes)}:${pad(secs)}`;
  }
  return `${minutes}:${pad(secs)}`;
};

export default prepareTimeFromSeconds;
