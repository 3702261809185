/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { logout as logoutLib } from '@citmed/auth';
import CoursesAPI, { coursesAPI } from '../apis/coursesAPI';
import { getJwtPayload } from '../utils/helper';
import { getBaseNameFixed } from '../apis/configs/getAuthConfig';

import TokenService from '../services/token.service';

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);

  // const { callbackUri } = getAuthConfig();

  useEffect(() => {
    if (isAccessAllowed) {
      loadUser(); // eslint-disable-line no-use-before-define
    }
  }, [isAccessAllowed]);

  /**
   * Get access token of the currently logged in user
   * @returns Promise with access token or error
   */
  const getAccess = async () => {
    const at = localStorage.getItem('at');
    if (at) {
      return Promise.resolve(at);
    }
    return Promise.reject(new Error('Access token not found'));
  };

  const setUserNull = () => {
    setUser(null);
  };

  // const restoreLocation = () => {
  //   const savedLocation = LocationService.getLocation();
  //   if (savedLocation) {
  //     window.location.href = savedLocation;
  //     return true;
  //   }
  //   return false;
  // };

  /**
   * Load logged in user
   */
  const loadUser = async () => {
    return getAccess()
      .then((token) => {
        const userFromToken = getJwtPayload(token);
        setUser(userFromToken);
        return userFromToken;
      })
      .catch(() => {
        setUserNull();
      });
  };

  // /**
  //  * Logs in by specified credentials and login type (saving JWT tokens and retrieving user data)
  //  * @param {String} login_ User login can be one of the following: Username, Phone number or Email.
  //  * @param {String} password User password or token from phone/email based on authentication type
  //  * @param {String} type Login type can be one of the following: 'Username', 'Phone' or 'Email'.
  //  * @param {Boolean} restoreLogoutLocation Flag to restore user logout location (Default: true)
  //  * @returns Promise with response or null
  //  */
  // const login = async (
  //   login_,
  //   password,
  //   type,
  //   restoreLogoutLocation = true
  // ) => {
  //   return coursesAPI.login(login_, password, type).then((resp) => {
  //     if (!resp) {
  //       setUser(null);
  //       return null;
  //     }
  //     // Everything OK
  //     if (resp.status === 200) {
  //       TokenService.setRefreshToken(resp.data.refreshToken);
  //       TokenService.setAccessToken(resp.data.accessToken);
  //       loadUser(resp.data.accessToken).then((respUser) => {
  //         // If user loaded, restore
  //         if (respUser) {
  //           if (restoreLogoutLocation) {
  //             restoreLocation();
  //           } else {
  //             LocationService.removeLocation();
  //           }
  //         }
  //       });
  //       return resp;
  //     }
  //     // Some expected errors
  //     TokenService.removeAccessToken();
  //     TokenService.removeRefreshToken();
  //     setUser(null);
  //     return resp;
  //   });
  // };

  // const requestRecoveryCode = async (email) => {
  //   return CoursesAPI.requestRecoveryCode(email)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       throw new Error(err);
  //     });
  // };

  // const recoverPasswordByEmail = async (email, code, password) => {
  //   return CoursesAPI.recoverPasswordByEmail(email, code, password)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       throw new Error(err);
  //     });
  // };

  // /**
  //  * Get 4-digit code by phone number
  //  * @param {String} phoneNumber Phone number to which to send the code
  //  * @returns Promise with response or null
  //  */
  // const requestLoginPhoneCode = async (phoneNumber) => {
  //   return coursesAPI
  //     .requestLoginPhoneCode(phoneNumber)
  //     .then((resp) => resp)
  //     .catch((resp) => resp);
  // };

  /**
   * Logs in by phone number and security code (saving JWT tokens and retrieving user data)
   * @param {String} phoneNumber Phone number
   * @param {String} code Security 4-digit code
   * @returns Promise with state of login operation
   */
  const loginByPhoneCode = async (phoneNumber, code) => {
    return coursesAPI.getJwtByPhoneCode(phoneNumber, code).then((resp) => {
      if (!resp) {
        return null;
      }
      // Everything OK
      if (resp.status === 200) {
        TokenService.setRefreshToken(resp.data.refreshToken);
        TokenService.setAccessToken(resp.data.accessToken);
        loadUser(resp.data.accessToken);
        return resp;
      }
      // Some unexpected errors
      TokenService.removeAccessToken();
      TokenService.removeRefreshToken();
      return resp;
    });
  };

  /**
   * Update user
   * @param {String} name Updated user name
   * @param {String} lastname Updated user lastname
   * @returns Promise with response or null
   */
  const updateUser = async (name, lastname) => {
    return getAccess()
      .then(async (token) => {
        const resp = await coursesAPI.updateUser(token, name, lastname);
        if (!resp) {
          return null;
        }
        // Everything is OK, update user with response
        if (resp.status === 200) {
          const updUser = { ...user };
          updUser.name = resp?.data?.given_name;
          updUser.lastName = resp?.data?.family_name;
          setUser(updUser);
        }
        return resp;
      })
      .catch(() => {
        setUserNull();
      });
  };

  const createUnregisteredUser = async (
    userPublicInfo,
    isRegisteredByPhone = false
  ) => {
    return coursesAPI
      .createUnregisteredUser(userPublicInfo, isRegisteredByPhone)
      .then((resp) => resp)
      .catch((resp) => resp);
  };

  // eslint-disable-next-line default-param-last
  const confirmInviteByCode = async (
    userPublicInfo,
    code,
    isRequestedByPhone = false,
    cancel
  ) => {
    return coursesAPI
      .confirmInviteByCode(userPublicInfo, code, isRequestedByPhone, cancel)
      .then((resp) => resp)
      .catch((resp) => resp);
  };

  // eslint-disable-next-line default-param-last
  const requestConfirmationCode = async (
    userPublicInfo,
    isRequestedByPhone = false,
    cancel
  ) => {
    return coursesAPI
      .requestConfirmationCode(userPublicInfo, isRequestedByPhone, cancel)
      .then((resp) => resp)
      .catch((resp) => resp);
  };

  /**
   * Register invited user
   * @param {String} email User's email
   * @param {String} phoneNumber User's phone number
   * @param {String} name User's name for profile
   * @param {String} lastName User's last name for profile
   * @param {String} password Password for login
   * @returns Server response or null
   */
  const registerInvitedUser = async (
    email,
    phoneNumber,
    name,
    lastName,
    password
  ) => {
    return coursesAPI
      .registerInvitedUser(email, phoneNumber, name, lastName, password)
      .then((resp) => {
        return resp;
      });
  };

  /**
   * Chnage password for current user
   * @param {String} currentPassword Current password
   * @param {String} newPassword New password to set
   * @returns Server response or null
   */
  const changePassword = async (currentPassword, newPassword) => {
    return getAccess()
      .then(async (token) => {
        const resp = await coursesAPI.changePassword(
          token,
          currentPassword,
          newPassword
        );
        return resp;
      })
      .catch(() => {});
  };

  /**
   * Serverless logout - just remove tokens
   */
  const logout = async () => {
    logoutLib(
      ENV?.CLIENT_ID,
      // TODO: заменить на callback uri из getAuthConfig, но не работает logout в таком случае.
      `${window.origin}${
        getBaseNameFixed() ? `/${getBaseNameFixed()}` : ''
      }/callback`,
      ENV?.AUTH_URL
    );
    setUser(null);
    // Remove saved location on forced logout
  };

  const value = useMemo(
    () => ({
      user,
      // login,
      loginByPhoneCode,
      // requestLoginPhoneCode,
      updateUser,
      setUserNull,
      createUnregisteredUser,
      confirmInviteByCode,
      requestConfirmationCode,
      registerInvitedUser,
      logout,
      setIsAccessAllowed,
      loadUser,
      changePassword,
      getAccess,
      // requestRecoveryCode,
      // recoverPasswordByEmail,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
